import React from 'react'
import FormColumn from "./formColumn"

export default ({
    section, 
    handleChange,
    values,
    errors
}) => {

    return (
        <div>
            { section.rowsHeading ?
                <h1>
                    { section.rowsHeading }
                </h1>
            : null }
            { section.copy ?
                <p>
                    { section.copy }
                </p>
            : null }
            <ul>
                { section.rows.map((row, index) => {
                    return (
                        <li className="row" key={index}>
                            { row.heading ?
                                <h4>
                                    { row.heading }
                                </h4>
                            : null }
                            <ul className={`columns columns-${row.columns.length}`}>
                                {row.columns.map((column, columnIndex) => {
                                    return (
                                        <li key={columnIndex}>
                                            <FormColumn 
                                                column={column} 
                                                handleChange={handleChange}
                                                values={values}
                                                error={errors[column.label]}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>   
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}