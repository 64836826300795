import React, { useState } from 'react'

import Button from './button'
import MasterplanMap from './masterplanMap'

import { useIsMobile } from 'utils'

export default ({ 
    title, 
    backgroundColour,
    sections
}) => {
    const [activeSection, setActiveSection] = useState(null)
    const [activeSectionVisible, setActiveSectionVisible] = useState(false)
    const [forceTransitionTransformOrigin, setForceTransitionTransformOrigin] = useState(false)
    
    const isMobile = useIsMobile()

    const handleClickBuilding = id => {
        const section = sections.find(section => section.buildingId === id)

        if(section){
            setActiveSection(section)

            setTimeout(() => {
                setActiveSectionVisible(true)
            },5)
        }
    }

    const closeActiveSection = _ => {
        setActiveSectionVisible(false)
    }

    const goToNextSection = _ => {
        const activeSectionId = activeSection.buildingId

        let nextSectionId = null

        allButtonIds.some((buttonId, index) => {
            if(buttonId === activeSectionId){
                if(allButtonIds[index + 1]){
                    nextSectionId = allButtonIds[index + 1]
                }else{
                    nextSectionId = allButtonIds[0]
                }
            }
            return false
        })

        if(nextSectionId){
            setForceTransitionTransformOrigin(true)

            setTimeout(() => {
                handleClickBuilding(nextSectionId)

                setTimeout(() => {
                    setForceTransitionTransformOrigin(false)
                },700)
            },10)
        }
    }

    const {
        title: sectionTitle,
        copy,
        images 
    } = activeSection || {}

    const allButtonIds = [
        '1',
        '2',
        '3',
        '4a',
        '4b',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        // '11',
        '12',
        '13',
        '14',
        // '15',
        '16',
        // '17',
        '18',
        'piazza',
        'a',
        // 'a-panopticon',
        'b',
        // 'b-annexe',
        // 'b-panopticon',
        'e',
        // 'admin',
        // 'former-warden',
        // 'guard-towers',
        // 'qm',
        // 'industry-lane',
        // 'rock-breaking-yard',
    ]

    return (
        <div className={'container'}>
            <div className="Masterplan-heading">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className="masterplan-outer">
                { isMobile &&
                    <div className={'mobile-prompt'}>
                        <h3>
                            Tap on a region to learn more about it.
                        </h3>
                    </div>
                }
                <div className={`content-container ${ activeSectionVisible ? 'visible' : '' }`}>
                    <button 
                        onClick={closeActiveSection}
                        className={'close'}
                    >
                        <i className={'fe fe-x'} />
                    </button>
                    <div className={'content-container-inner'}>
                        { sectionTitle &&
                            <>
                                { (images && images.length) &&
                                    <ul className="images">
                                        { images.map(image => (
                                            <li key={image.mediaItemUrl}>
                                                <img src={image} alt={ sectionTitle } />
                                            </li>
                                        ))}
                                    </ul>
                                }
                                <h3>
                                    { sectionTitle }
                                </h3>
                                <div 
                                    className="copy" 
                                    dangerouslySetInnerHTML={{ __html: copy }}
                                />
                            </>
                        }
                        <Button
                            onClick={goToNextSection}
                            label={'Next'}
                        />
                    </div>
                </div>
                <div className="masterplan">
                    <MasterplanMap 
                        handleClickBuilding={handleClickBuilding}
                        activePosition={activeSection ? activeSection.position : {
                            top: 50,
                            left: 50
                        }}
                        activeSectionId={activeSection ? activeSection.buildingId : null}
                        active={activeSectionVisible}
                        forceTransitionTransformOrigin={forceTransitionTransformOrigin}
                    />
                </div>
            </div>
        </div>
    )
}
