import React from "react"
import Link from 'components/link'
import { Date, EventType } from 'utils'

export default ({ 
    item, 
    nextPrev,
    isWhatsOn
}) => {
    if (!item) {
        return null
    }
    const {
        slug,
        title,
        excerpt,
        content,
        featuredImage,
        newsFields,
        eventFields,
        categories,
        types
    } = item

    const _featuredImage = featuredImage ? featuredImage.node.mediaItemUrl : ''

    const formattedDate = (newsFields || eventFields).displayDate ? (newsFields || eventFields).displayDate : (newsFields || eventFields).date ? <Date date={(newsFields || eventFields).date} includeYear="true" /> : null

    const _excerpt = isWhatsOn ? (() => {
        if(!content){
            return ''
        }

        let __excerpt = content.replace(/(<([^>]+)>)/ig, "").slice(0, 160)

        if (__excerpt.length === 160) {
            __excerpt += '...'
        }

        return __excerpt
    })() : excerpt

    const urlSegment = isWhatsOn ? 'whats-on' : 'news-and-stories'

    const useLink = ( types && types.edges.length ) ? (types.edges[0].node.name === 'Coming Soon') ? false : true : true

    const itemContent = (
        <>
            { nextPrev || formattedDate &&
                <div className={nextPrev ? "newsItem-date nextPrev" : "newsItem-date"}>
                    {nextPrev ? nextPrev : formattedDate}
                </div>
            }
            <div className="newsItem-image-cont">
                <div 
                    className={`newsItem-image ${_featuredImage ? 'hasImage' : ''}`}
                    style={{
                        backgroundImage: `url(${ _featuredImage || ''})`
                    }}
                />
            </div>
            <div className="newsItem-text">
                <div className="newsItem-title">
                    { categories &&
                        <span dangerouslySetInnerHTML={{ __html: categories.edges[0].node.name }} />
                    }
                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <div className="newsItem-excerpt" dangerouslySetInnerHTML={{ __html: _excerpt }} />
                { useLink &&
                    <button className="button">
                        { isWhatsOn ?
                            <>
                                View <EventType name={ ( types && types.edges.length ) ? types.edges[0].node.name : 'Event' } />
                            </>
                        :
                            'Read more'
                        }
                    </button>
                }
            </div>
        </>
    )

    return (
        useLink ?
            <Link
                to={`/${ urlSegment }/${ slug }`} 
                className="newsItem"
            >
                { itemContent }
            </Link>
        :
            <div
                className="newsItem"
            >
                { itemContent }
            </div>
    )
}
