import React from 'react'

export default ({ title, copy, copy2, featureColour, backgroundColour }) => {

    return (

        <div className={ backgroundColour ? 'TwoColumnTextBlock-cont ' + backgroundColour : 'TwoColumnTextBlock-cont' }>

            <div className={ featureColour ? 'container ' + featureColour : 'container' }>

                <h1 dangerouslySetInnerHTML={{ __html: title }} />

                <div className="TwoColumnTextBlock-columns">

                    <div dangerouslySetInnerHTML={{ __html: copy }} />

                    <div dangerouslySetInnerHTML={{ __html: copy2 }} />

                </div>

            </div>

        </div>
        
    )
}
