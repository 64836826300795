import React, { useContext, useEffect, useRef, useState } from "react"
import { MainContext } from "context/context"
import { useMutation } from '@apollo/client'
import Moment from 'moment'
import ReactPlayer from 'react-player'
import { SUBMIT_CHRISTMAS_ENTRY, CHECK_CHRISTMAS_WINNER } from '../../graphql/queries'


import { useLocation } from '@reach/router';
import queryString from 'query-string';

import ChristmasPrizeResult from './christmasPrizeResult'

import ChristmasPresents from '../../images/christmas-presents.svg'
import ChristmasTree from '../../images/christmas-tree.svg'
import ChristmasTree1 from '../../images/christmas-tree-1.svg'

import Subscribe from './../subscribe'

export default ({
    title,
    copy,
}) => {
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [result, setResult] = useState('')
    const [hasChecked, setHasChecked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isWinner, setIsWinner] = useState(false)
    const [prizeTitle, setPrizeTitle] = useState('')
    const [prizeImage, setPrizeImage] = useState('')

    const VideoRef = useRef(null)

    const [submitChristmasEntry] = useMutation(SUBMIT_CHRISTMAS_ENTRY)
    const [checkChristmasWinner] = useMutation(CHECK_CHRISTMAS_WINNER)

    const {
        addInvertedHeaderSection,
        setIsHeaderInverted,
    } = useContext(MainContext)

    const elementRef = useRef(null)
    const formRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)
        }
    }, [])

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        if (_window) {
            _window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [isLoading])

    const handleCheck = () => {

        setIsLoading(true)

        checkChristmasWinner({
            variables: {
                name: 'check',
            },
        }).then(response => {

            setHasChecked(true)

            if (response.data.checkChristmasWinner.result === 'winner') {
                setIsWinner(true)
                setIsHeaderInverted(true)

                setPrizeTitle(response.data.checkChristmasWinner.prize)
                setPrizeImage(response.data.checkChristmasWinner.prizeImage)
            } else {
                setIsHeaderInverted(true)
            }

        }).catch(error => {
            console.log(error)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!name) {
            setError('Please enter your name.')

            setTimeout(() => {
                setError('')
            }, 2000)

            return false
        }

        if (!email) {
            setError('Please enter your email.')

            setTimeout(() => {
                setError('')
            }, 2000)

            return false
        }

        submitChristmasEntry({
            variables: {
                name: name,
                email: email,
            },
        }).then(response => {

            const _result = {
                name: response.data.submitChristmasEntry.name,
                result: response.data.submitChristmasEntry.result,
                prize: response.data.submitChristmasEntry.prize,
                prizeImage: response.data.submitChristmasEntry.prizeImage,
                code: response.data.submitChristmasEntry.code,
            }

            setResult(_result)

            setIsHeaderInverted(true)

            formRef.current.reset()
        }).catch(error => {
            console.log(error)
        })
    }

    const handleChange = (label, value) => {
        if (label === 'name') {
            setName(value)
        } else {
            setEmail(value)
        }
    }

    const handleVideoProgress = (progress) => {
        if (progress.played === 1) {
            setIsLoading(false)
        }
    }

    const daysLeft = 25 - Moment().format('D')

    const videoUrl = 'https://admin.pentridgecoburg.com.au/christmas-videos/Day-' + (daysLeft > 0 && daysLeft < 25 ? daysLeft : 1) + '.mp4'



    const [isCompActive, setIsCompActive] = useState(false)

    const location = useLocation()

    useEffect(() => {
        if ( Moment().isAfter('2021-12-01') && Moment().isBefore('2021-12-25') ) {
            setIsCompActive(true)
        }
        if ( location.search.includes('testComp') ) {
            setIsCompActive(true)
        }
    }, [location])

    return (
        ( !isCompActive ) ?
            <div className={`ChristmasPrizeResult splash`}>
                <div className="container">
                    <div className="ChristmasPrizeResult-content">
                        <div className="ChristmasPrizeForm-title">
                            <h1 dangerouslySetInnerHTML={{ __html: 'Tis the season to celebrate' }} />
                            <h1 class="christmas-hat" dangerouslySetInnerHTML={{ __html: 'at Pentridge' }} />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: 'Enjoy the holidays!' }} />
                        <img src={ ChristmasTree1 } />
                    </div>
                </div>
            </div>
        :
            <>
                <div className={`ChristmasPrizeForm-loading${isLoading ? ' show' : ''}`}>
                    <ReactPlayer
                        url={videoUrl}
                        playing={isLoading}
                        playsinline={true}
                        progressInterval={10}
                        onProgress={(progress) => {
                            handleVideoProgress(progress)
                        }}
                        className='videoPlayer-player'
                        config={{
                            file: {
                                attributes: {
                                    preload: 'auto'
                                }
                            }
                        }}
                        ref={ref => VideoRef.current = ref}
                    />
                </div>
                {(hasChecked && !isWinner) ?
                    <div className={`ChristmasPrizeResult`}>
                        <div className="container">
                            <div className="ChristmasPrizeResult-content">
                                <h1 dangerouslySetInnerHTML={{ __html: 'Sorry!<br />You missed out' }} />
                                <div dangerouslySetInnerHTML={{ __html: 'All prizes for today have been claimed. Be quick and try again tomorrow.' }} />
                                <div className="ChristmasPrizeResult-subscribe">
                                    <h2 dangerouslySetInnerHTML={{ __html: 'Sign up to our newsletter' }} />
                                    <div dangerouslySetInnerHTML={{ __html: 'Sign up to receive news, stories and special event invites.' }} />
                                    <Subscribe />
                                </div>
                                <img src={ ChristmasTree } />
                            </div>
                        </div>
                    </div>
                : '' }
                <ChristmasPrizeResult isWinner={ isWinner } hasChecked={ hasChecked } result={ result } />
                { ( isWinner && result ) ?
                    null
                    :
                    <div
                        className={`ChristmasPrizeForm-cont${(!result && !isWinner && hasChecked) ? ' ChristmasPrizeForm-cont-result' : ''}`}
                        ref={ref => elementRef.current = ref}
                    >
                        <div className="container">
                            { title && <div className="ChristmasPrizeForm-title" dangerouslySetInnerHTML={{ __html: isWinner ? "<h1 class='christmas-hat-2'><span>Congratulations!</span></h1>" : title }} /> }
                            { prizeTitle && <div className="ChristmasPrizeForm-prizeTitle" dangerouslySetInnerHTML={{ __html: prizeTitle }} /> }
                            { prizeImage &&
                                <div className="ChristmasPrizeForm-prizeImage">
                                    <img src={ prizeImage } />
                                </div>
                            }
                            { copy && <div className="ChristmasPrizeForm-copy" dangerouslySetInnerHTML={{ __html: isWinner ? 'Please fill in the below details & we will email you shortly with a unique code to claim your prize.' : copy }} /> }
                            <div className="ChristmasPrizeForm-form">
                                <form ref={ref => formRef.current = ref} onSubmit={e => { handleSubmit(e) }}>
                                    {!isWinner ?
                                        <button
                                            onClick={handleCheck}
                                            type="submit"
                                            className="button"
                                        >
                                            Wish me luck!
                                        </button>
                                    :
                                        <>
                                            <input type="text" name="name" placeholder="First name" onChange={e => handleChange('name', e.target.value)} />
                                            <input type="email" name="email" placeholder="Email address" onChange={e => handleChange('email', e.target.value)} />
                                            <div className="ChristmasPrizeForm-form-error" dangerouslySetInnerHTML={{ __html: error }} />
                                            <button type="submit" className="button">Claim your prize</button>
                                            <div className="ChristmasPrizeForm-form-disclaimer" dangerouslySetInnerHTML={{ __html: 'By submitting you will be added to Pentridge Coburg mailing list' }} />
                                        </>
                                    }
                                </form>
                                <img src={ ChristmasPresents } />
                            </div>
                        </div>
                    </div>
                }
            </>
    )
}
