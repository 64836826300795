import React, { useState } from "react"
import FormTab from "components/formTab"
import FadeCollapse from 'components/formFields/fadeCollapse'
import { isEqual } from 'lodash'

import Loader from 'components/loader'

import { emailIsValid } from 'utils'

export default ({ 
    form, 
    handleSubmit,
    isLoading,
    error,
    fieldValues,
    handleChange
}) => {
    const [errors, setErrors] = useState({})
    const [checkboxChecked, setCheckboxChecked] = useState(false)

    const activeSection = form ? form.formFields.sections[0] : null

    const {
        buttonLabel,
        checkboxConfirmCopy,
        disclaimer,
    } = form.formFields

    const checkIfValid = () => {
        let _isValid = true
        const _errors = {}

        if(checkboxConfirmCopy && !checkboxChecked){
            _isValid = false
        }

        activeSection.rows.forEach(row => {
            row.columns.forEach(({ label }) => {
                const value = fieldValues[label]

                const lowercaseLabel = label.toLowerCase()

                const isSignUp = lowercaseLabel.indexOf('sign up') !== -1 || lowercaseLabel.indexOf('subscribe') !== -1

                if(!fieldValues[label] && !isSignUp){
                    _isValid = false
                }else{
                    switch(label){
                        case 'Confirm Password':
                            if(fieldValues['Password'] !== value){
                                _errors[label] = true
                            }
                            break
                        case 'Email Address':
                        case 'Email':
                            if(!emailIsValid(value)){
                                _errors[label] = true
                            }
                            break
                        default:
                            _errors[label] = ''
                            break
                    }
                }
            })
        })

        if(!isEqual(_errors, errors)){
            setErrors(_errors)
        }

        return _isValid
    }

    const isValid = checkIfValid()

    return (
        <>
            { activeSection ?
                <form 
                    className="form-container" 
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(fieldValues)
                    }}
                >
                    <Loader visible={isLoading} />
                    <FadeCollapse open={!!error}>
                        <div className="error">
                            <i className="fa fa-exclamation-triangle" />
                            { error }
                        </div>
                    </FadeCollapse>
                    <div className="form-tabs">
                        <div className={"tab"}>
                            <FormTab 
                                section={activeSection} 
                                handleChange={handleChange}
                                values={fieldValues}
                                errors={errors}
                            />
                        </div>
                        { checkboxConfirmCopy ? 
                            <label className={`checkbox-confirm-copy ${ checkboxChecked ? 'checked' : '' }`}>
                                <div className={'checkbox'}>
                                    <input 
                                        type={'checkbox'} 
                                        checked={checkboxChecked}
                                        onChange={() => setCheckboxChecked(!checkboxChecked)}
                                    />
                                    <span />
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: checkboxConfirmCopy}} className="copy" />
                            </label>
                        : null }
                        <button 
                            type="submit" 
                            className='button outline'
                            disabled={!isValid}
                        >
                            { buttonLabel || 'Submit' } 
                        </button>
                        { disclaimer ? 
                            <div dangerouslySetInnerHTML={{ __html: disclaimer}} className="copy" />
                        : null }
                    </div>
                </form>
            : null 
            }
        </>
    )
}