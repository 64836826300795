import React from "react"
import DevelopmentItem from 'components/developmentItem'

export default ({ title, developments, columns, backgroundColour }) => {
    
    return (

        <div className={ backgroundColour ? 'DevelopmentShowcase-cont ' + backgroundColour : 'DevelopmentShowcase-cont' }>

            <div className='container'>

                <h1 dangerouslySetInnerHTML={{ __html: title }} />

                <ul className={ columns }>

                    {developments.map((row, index) => {

                        const {
                            development
                        } = row

                        return (
                            <li key={index}>

                                <DevelopmentItem item={ development } />

                            </li>
                        )
                    })}

                </ul>
                
            </div>
        
        </div>
        
    )
}
