import React from "react"
import Link from 'components/link'
import DirectoryItem from 'components/directoryItem'

export default ({ 
    title, 
    directory, 
    columns, 
    showHours, 
    showLinks, 
    showDirectoryLink, 
    backgroundColour 
}) => {
    return (
        <div className={ backgroundColour ? 'DirectoryShowcase-cont ' + backgroundColour : 'DirectoryShowcase-cont' }>
            <div className='container'>
                <div dangerouslySetInnerHTML={{ __html: title }} />
                <ul className={ columns }>
                    {directory.map((row, index) => {
                        const {
                            place,
                            imageMask
                        } = row

                        return (
                            <li key={index}>
                                <DirectoryItem 
                                    item={ place } 
                                    imageMask={ imageMask } 
                                    showHours={ showHours } 
                                    showLink={ showLinks } 
                                />
                            </li>
                        )
                    })}

                </ul>
                {showDirectoryLink && 
                    <Link 
                        to="/directory/" 
                        dangerouslySetInnerHTML={{ __html: 'Directory' }} 
                        className="button border" 
                    />
                }
            </div>
        </div>
    )
}
