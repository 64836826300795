import React from 'react'

export default ({ copy, featureColour, backgroundColour }) => {

    return (

        <div className={ backgroundColour ? 'TextBlock-cont ' + backgroundColour : 'TextBlock-cont' }>

            <div className={ featureColour ? 'container ' + featureColour : 'container' }>

                <div dangerouslySetInnerHTML={{ __html: copy }} />

            </div>

        </div>
        
    )
}
