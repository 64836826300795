import React from 'react'

export default ({
    onClick,
    label
}) => {
    return (
        <button className={'button'} onClick={onClick}>
            { label }
        </button>
    )
}