import React from "react"
import Link from 'components/link'

export default ({ image, boxes, backgroundColour }) => {
    const _boxes = boxes.filter(row => {
        return !row.hide && row.title
    })
    
    return (
        <div className={ backgroundColour ? 'FeatureBoxes-cont ' + backgroundColour : 'FeatureBoxes-cont' }>
            <div className='container'>
                <ul className={ 'FeatureBoxes-' + ( _boxes.length + 1 ) }>
                    <li>
                        <div className="FeatureBoxes-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }}></div>
                        <svg width="0" height="0">
                            <defs>
                                <clipPath id="clipPath-x" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                    <path d="M500,366.57,383.43,250,500,133.43,366.57,0,250,116.57,133.43,0,0,133.43,116.56,250,0,366.57,133.43,500,250,383.43,366.57,500Z" />
                                </clipPath>
                            </defs>
                        </svg>
                    </li>
                    {_boxes.map((row, index) => {
                        const {
                            title,
                            colour,
                            link,
                            image
                        } = row

                        return (
                            <li 
                                key={index} 
                                style={{
                                    transitionDelay: ((index + 1)/10)+'s'
                                }}
                            >
                                <Link 
                                    to={ link.url } 
                                    title={ link.title } 
                                    className={ colour + ' bg' }
                                >
                                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                                    <div className="FeatureBoxes-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }}></div>
                                </Link>
                            </li>
                        )
                    })}

                </ul>

            </div>

        </div>

    )
}
