import React, { useRef, useState } from "react"
import { useMutation } from '@apollo/client'
import { CHECK_CHRISTMAS_CODE, REDEEM_CHRISTMAS_CODE } from '../../graphql/queries'

import Loader from "images/loader.svg"

export default ({
    title,
}) => {
    const [error, setError] = useState('')
    const [code, setCode] = useState('')
    const [voucher, setVoucher] = useState({})
    const [isRedeeming, setIsRedeeming] = useState(false)

    const [checkChristmasCode] = useMutation(CHECK_CHRISTMAS_CODE)
    const [redeemChristmasCode] = useMutation(REDEEM_CHRISTMAS_CODE)

    const formRef = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if ( !code ) {
            setError( 'Please enter a voucher code.' )

            setTimeout(() => {
                setError( '' )
            }, 2000)

            return false
        }

        checkChristmasCode({
            variables: {
                code: code,
            },
        }).then(response => {
            if ( response.data.checkChristmasCode.success ) {
                const _voucher = {
                    prize: response.data.checkChristmasCode.prize,
                    expiryDate: response.data.checkChristmasCode.expiryDate,
                    code: response.data.checkChristmasCode.code,
                    redeemed: response.data.checkChristmasCode.redeemed,
                }

                setVoucher( _voucher )

                if ( _voucher.prize === '' ) {
                    setError( 'Not a valid code' )

                    setTimeout(() => {
                        setError( '' )
                    }, 2000)
                }
                // formRef.current.reset()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleChange = ( value ) => {
        setCode(value)
    }

    const handleRedeem = ( code ) => {

        setIsRedeeming(true)

        redeemChristmasCode({
            variables: {
                code: code,
            },
        }).then(response => {
            if ( response.data.redeemChristmasCode.success ) {
                const _voucher = {
                    prize: response.data.redeemChristmasCode.prize,
                    expiryDate: response.data.redeemChristmasCode.expiryDate,
                    code: response.data.redeemChristmasCode.code,
                    redeemed: response.data.redeemChristmasCode.redeemed,
                }
                
                setVoucher( _voucher )

                setIsRedeeming(false)

                // formRef.current.reset()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div className="ChristmasPrizeChecker-cont">
            <div className="container">
                { title && <div className="ChristmasPrizeChecker-title" dangerouslySetInnerHTML={{ __html: title }} /> }
                <div className="ChristmasPrizeChecker-form">
                    <p dangerouslySetInnerHTML={{ __html: 'Redeem or check status of voucher' }} />
                    <form ref={ref => formRef.current = ref} onSubmit={e => { handleSubmit(e) }}>
                        <input type="text" name="code" placeholder="Enter voucher code" onChange={e => handleChange(e.target.value)} />
                        <div className="ChristmasPrizeChecker-form-error" dangerouslySetInnerHTML={{ __html: error }} />
                        <button type="submit" className="button">Check</button>
                    </form>
                </div>
                { voucher.prize ?
                    <div className={ `ChristmasPrizeChecker-voucher${ voucher.redeemed === 'true' ? ' redeemed' : '' }` }>
                        <div className="ChristmasPrizeChecker-voucher-details">
                            <h3 dangerouslySetInnerHTML={{ __html: 'Voucher #' + voucher.code }} />
                            <div dangerouslySetInnerHTML={{ __html: voucher.prize }} />
                            { voucher.expiryDate ?
                                <div className="ChristmasPrizeChecker-voucher-expiryDate" dangerouslySetInnerHTML={{ __html: 'Exp: ' + voucher.expiryDate }} />
                            : null }
                        </div>
                        <div className={`ChristmasPrizeChecker-voucher-loading${ isRedeeming ? ' show' : '' }`}>
                            <img src={ Loader } />
                        </div>
                        { voucher.redeemed === 'false' ?
                            <div className="ChristmasPrizeChecker-voucher-button">
                                <div className="button border" onClick={() => handleRedeem(voucher.code)}>Redeem</div>
                            </div>
                        : null }
                    </div>
                : null }
            </div>
        </div>

    )
}
