import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from 'components/slider'

import { useIsMobile } from 'utils'

export default ({
    title,
    instagramLink,
    backgroundColour
}) => {
    return null
    // const [hasMounted, setHasMounted] = useState(false)

    // useEffect(() => {
    //     setTimeout(() => {
    //         setHasMounted(true)
    //     },1)
    // }, [])

    // const instagramHandle = instagramLink.replace('https://www.instagram.com/', '').replace(',','')
    
    // const isMobile = useIsMobile()

    // const { nodes } = useStaticQuery(graphql`
    //     query {
    //         allInstagramContent(limit: 10) {
    //             nodes {
    //                 permalink
    //                 media_id
    //                 localImage {
    //                 absolutePath
    //                 childImageSharp {
    //                     fluid(maxHeight: 500, maxWidth: 500) {
    //                         src
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    // `).allInstagramContent

    // const flickityOptions = {
    //     wrapAround: true,
    //     prevNextButtons: true,
    //     pageDots: isMobile ? true : false,
    //     draggable: true,
    //     cellAlign: 'left',
    // }

    // return (
    //     <div className={backgroundColour ? 'InstagramFeed-cont ' + backgroundColour : 'InstagramFeed-cont'}>
    //         <div className='container'>
    //             <div className="InstagramFeed-heading">
    //                 <h1>
    //                     {title}
    //                     <a
    //                         href={instagramLink}
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                         dangerouslySetInnerHTML={{ __html: '@' + instagramHandle }}
    //                     />
    //                 </h1>
    //             </div>
    //             { hasMounted &&
    //                 <Slider
    //                     className={'InstagramFeed-slideshow'}
    //                     options={flickityOptions}
    //                 >
    //                     { nodes && nodes.map((item, index) => {
    //                         if (!item?.localImage) {
    //                             return null
    //                         }

    //                         return (
    //                             <div className={'InstagramFeed-slideshow-image'} key={index}>
    //                                 <a href={item.permalink} target="_blank">
    //                                     <img src={item?.localImage?.childImageSharp?.fluid?.src} alt="Instagram feed image" />
    //                                 </a>
    //                             </div>
    //                         )
    //                     })}
    //                 </Slider>
    //             }
    //         </div>
    //     </div>
    // )
}
