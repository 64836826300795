import React, { useState } from "react"

import { cloneDeep } from 'lodash'

export default ({ title, copy, backgroundColour, contactGroup }) => {

    const [openContactGroups, setOpenContactGroup] = useState([])

    const setContactsOpen = openKey => {
        const _openContactGroups = cloneDeep(openContactGroups)

        const indexIfExists = _openContactGroups.indexOf(openKey)

        if(indexIfExists === -1){
            _openContactGroups.push(openKey)
        }else{
            _openContactGroups.splice(indexIfExists, 1)
        }

        setOpenContactGroup(_openContactGroups)
    }

    return (

        <div className={ backgroundColour ? 'Contacts-cont bg ' + backgroundColour : 'Contacts-cont' }>

            <div className="container">

                <div className="Contacts-text">

                    <div dangerouslySetInnerHTML={{ __html: title }} />

                    <div dangerouslySetInnerHTML={{ __html: copy }} />

                </div>

                <div className="Contacts-groups">

                    {contactGroup.map((row, index) => {

                        const {
                            title,
                            name,
                            address,
                            contacts,
                            logo
                        } = row

                        const _logo = logo ? logo.mediaItemUrl : ''

                        const openKey = index
                        const isOpen = openContactGroups.indexOf(openKey) !== -1

                        return (

                            <div className="Contacts-group" key={index} onClick={ () => setContactsOpen(openKey) } onKeyDown={ () => setContactsOpen(openKey) } role="button" tabIndex={ index }>

                                <h3 dangerouslySetInnerHTML={{ __html: title }} />

                                <div className={isOpen === true ? 'Contacts-group-main open' : 'Contacts-group-main'} >

                                    <div className="Contacts-toggle" dangerouslySetInnerHTML={{ __html: '<span></span>' }} />

                                    { _logo ? <img src={ _logo } alt={ name } /> : '' }

                                    <div className="Contacts-group-text">

                                        <h4 dangerouslySetInnerHTML={{ __html: name }} />

                                        <div className="Contacts-group-text-address" dangerouslySetInnerHTML={{ __html: address }} />

                                        <div className="Contacts-group-contacts">

                                            {contacts.map((row, index) => {

                                                const {
                                                    name,
                                                    mobile,
                                                    email
                                                } = row

                                                return (

                                                    <div key={index}>

                                                        <div dangerouslySetInnerHTML={{ __html: name }} />

                                                        <div dangerouslySetInnerHTML={{ __html: '<span>M: </span>' + mobile }} />

                                                        <a href={ 'mailto:' + email } dangerouslySetInnerHTML={{ __html: email }} />

                                                    </div>

                                                )

                                            })}
                                    
                                        </div>
                                    
                                    </div>
                                
                                </div>



                            </div>
                            
                        )

                    })}

                </div>

            </div>

        </div>
        
    )
}
