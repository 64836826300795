import React from "react"
import Link from "components/link"

const DirectorySection = ({
    title,
    link,
    items,
    centerTitle,
    scrollId,
}) => {
    return (
        <div className="DirectorySection" id={scrollId}>
            <div className="container">
                <div className={`DirectorySection-top ${centerTitle ? 'center' : ''}`}>
                    {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
                    {link && <Link to={link.url} className="button">{link.title}</Link>}
                </div>
                <div className="DirectorySection-items">
                    {items && items.map(({ item }, index) => {
                        const {
                            title,
                            copy,
                            url,
                            image,
                            externalLink,
                            place
                        } = item

                        const content = (() => {
                            if (!externalLink) {
                                return {
                                    image: place.featuredImage.node,
                                    title: place.title,
                                    copy: place.directoryFields.excerpt,
                                    url: `directory/${place.slug}`
                                }
                            } return {
                                image,
                                title,
                                copy,
                                url
                            }
                        })()

                        return (
                            <div className="DirectorySection-item" key={index}>
                                <Link to={content.url}>
                                    <div className="DirectorySection-item-imageCont">
                                        {content.image && <img src={content.image.mediaItemUrl} alt={content.title} />}
                                    </div>
                                    <div className="DirectorySection-item-main">
                                        <div>
                                            {content.title && <h2 dangerouslySetInnerHTML={{ __html: content.title }} />}
                                            <div className="copy x-small">
                                                <div dangerouslySetInnerHTML={{ __html: content.copy }} />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="button copy x-small">Learn More</button>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default DirectorySection