import React from "react"
import Link from 'components/link'

export default ({ images, copy, featureColour, style, link, imageFirst, backgroundColour }) => {

    let containerClass = 'container'
    containerClass = imageFirst ? containerClass += ' reverse' : containerClass
    containerClass = style === 'style2' ? containerClass += ' style2' : containerClass
    
    const imageCount = images.length

    return (

        <div className={ backgroundColour ? 'MultipleImagesWithCopy-cont ' + backgroundColour : 'MultipleImagesWithCopy-cont' }>

            <div className={ containerClass }>
                
                <div className={ 'MultipleImagesWithCopy-copy ' + featureColour }>

                    <div dangerouslySetInnerHTML={{ __html: copy }} />

                    { link ? <Link to={ link.url } dangerouslySetInnerHTML={{ __html: link.title }} className="button" /> : '' }
                    
                </div>

                <ul className={ 'MultipleImagesWithCopy-image-cont imageCount-' + imageCount }>

                    {images.map((row, index) => {

                        const {
                            image
                        } = row

                        return (

                            <li key={index}>

                                <div className="MultipleImagesWithCopy-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />

                            </li>
                            
                        )

                    })}

                </ul>
                
            </div>
                
        </div>
        
    )
}
