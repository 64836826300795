import React from 'react'

import TextInput from "components/formFields/textInput"
import TextArea from "components/formFields/textArea"
import Checkboxes from 'components/formFields/checkboxes'
import Radiobuttons from 'components/formFields/radiobuttons'
import Select from 'components/formFields/selectBox'
import SubscribeCheckbox from 'components/formFields/subscribeCheckbox'

export default ({
    column, 
    values, 
    handleChange,
    error
}) => {
    let Component = null 
    let className = ''

    switch(column.type) {
        case 'text':
        case 'email':
        case 'password':
            Component = TextInput
            break
        case 'select':
            className = 'select'
            Component = Select
            break
        case 'textarea':
            Component = TextArea
            break
        case 'checkboxes':
            Component = Checkboxes
            break
        case 'radiobuttons':
            Component = Radiobuttons
            break
        case 'subscribeCheckbox':
            Component = SubscribeCheckbox
            break
        default:
            Component = TextInput
            break
        }

    const inputKey = column.label

    return (
        <div className={error ? 'has-error' : ''}>
            <Component 
                label={column.label} 
                options={column.options} 
                inputKey={inputKey}
                handleChange={value => handleChange(inputKey, value)}
                className={className}
                value={values[inputKey] || undefined}
                type={column.type}
            />
        </div>
    )
}