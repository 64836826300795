import React, { useRef, useEffect, useState } from "react"

export default ({ slide, staticText, smallerText, backgroundColour }) => {
    const intervalRef = useRef(null)

    const [activeIndex, setActiveIndex] = useState(0)
    const activeIndexRef = useRef(activeIndex)
    activeIndexRef.current = activeIndex

    const indices = 3

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            const newIndex = (activeIndexRef.current + 1) < indices ? activeIndexRef.current + 1 : 0

            setActiveIndex(newIndex)
        },2000)

        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])

    return (
        <div className={backgroundColour ? 'HomepageFeatureSlideshow-cont ' + backgroundColour : 'HomepageFeatureSlideshow-cont'}>
            <div className="container">
                <div className="HomepageFeatureSlideshow-item">
                    <div className="HomepageFeatureSlideshow-title">
                        <h1 className={ smallerText ? "smallerText" : "" }>
                            { slide.map(({ title }, index) => {
                                return (
                                    <strong key={index} className={activeIndex === index ? 'active' : ''} dangerouslySetInnerHTML={{ __html: title + " " }} />
                                )
                            })}
                            { staticText && <em dangerouslySetInnerHTML={{ __html: staticText }} /> }
                        </h1>
                    </div>
                    <div className="HomepageFeatureSlideshow-image-cont">
                        <div className="HomepageFeatureSlideshow-image">
                            { slide.map(({ image }, index) => {
                                return (
                                    <div 
                                        key={index} 
                                        style={{
                                            backgroundImage: `url(${ image.mediaItemUrl })`
                                        }} 
                                        className={activeIndex === index ? 'active' : ''}
                                    />
                                )
                            })}
                        </div>
                        <svg width="0" height="0">
                            <defs>
                                <clipPath id="clipPath-arch1" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                    <path d="M500,550V250.33c0-333.77-500-333.77-500,0V550Z" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}
