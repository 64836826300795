import React from "react"
import Link from 'components/link'
import Hours from 'components/hours'
import { useIsMobile } from 'utils'

export default ({
    item,
    imageMask,
    showHours,
    showLink
}) => {
    const {
        slug,
        title,
        content,
        directoryFields,
        featuredImage,
    } = item

    const isMobile = useIsMobile()

    let excerpt = content ? content.replace(/(<([^>]+)>)/ig, "") : ''
    excerpt = excerpt ? excerpt.slice(0, 160) : ''
    if (excerpt.length === 160) {
        excerpt = excerpt + '...'
    }

    if (isMobile) {
        excerpt = excerpt ? excerpt.slice(0, 80) : ''
        if (excerpt.length === 80) {
            excerpt = excerpt + '...'
        }
    }

    const {
        logo: _logo,
        logoBackgroundColour
    } = directoryFields

    excerpt = directoryFields.excerpt ? directoryFields.excerpt : excerpt

    const _featuredImage = featuredImage ? featuredImage.node.mediaItemUrl : ''

    const logo = _logo ? _logo.mediaItemUrl : ''
    const hours = directoryFields.openingHours

    const directoryItemContent = (
        <>
            <div
                className={'directoryItem-image-cont ' + imageMask}
            >
                <div className="bg" style={{ backgroundImage: `url(${_featuredImage})` }} />
                <div className={`directoryItem-image ${imageMask} ${ logoBackgroundColour ? 'hide-after' : '' }`}
                    style={{
                        backgroundColor: logoBackgroundColour ? `#${logoBackgroundColour}` : undefined
                    }}
                >
                    <div className="directoryItem-logo" style={{ backgroundImage: `url(${logo})` }} />
                </div>
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="clipPath-x" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                            <path d="M500,366.57,383.43,250,500,133.43,366.57,0,250,116.57,133.43,0,0,133.43,116.56,250,0,366.57,133.43,500,250,383.43,366.57,500Z" />
                        </clipPath>
                    </defs>
                </svg>
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="clipPath-arch1" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                            <path d="M500,550V250.33c0-333.77-500-333.77-500,0V550Z" />
                        </clipPath>
                    </defs>
                </svg>
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="clipPath-arch2" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                            <path id="Path_158" data-name="Path 158" d="M0,550V142.31s-2.9-40,38.46-60.67S198.48,0,250.08,0,420.22,60.76,461.57,81.64,500,142.4,500,142.4V550Z" transform="translate(0)" />
                        </clipPath>
                    </defs>
                </svg>
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="clipPath-circle" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                            <circle cx="250" cy="250" r="250" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className="directoryItem-text">
                <div className={showHours && hours ? 'directoryItem-title hasHours' : 'directoryItem-title'}>
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    {showHours &&
                        <div className="hours-cont">
                            <Hours hours={hours} />
                        </div>
                    }
                </div>
                {excerpt && <div dangerouslySetInnerHTML={{ __html: excerpt }} />}
                {showLink && (title === 'Coming Soon' || slug === 'coming-soon') ? '' : <button className="button">View</button>}
            </div>
        </>
    )

    return (
        <>
            {title === 'Coming Soon' || slug === 'coming-soon' ?
                <div className="directoryItem">
                    {directoryItemContent}
                </div>
            :
                <Link to={'/directory/' + slug} className="directoryItem">
                    {directoryItemContent}
                </Link>
            }
        </>
    )
}
