import React, { useContext, useEffect, useRef, useCallback } from "react"
import { MainContext } from "context/context"
import { useIsMobile } from 'utils'
import { useStaticQuery, graphql } from "gatsby"

import Link from 'components/link'
import Slider from 'components/slider'

export default ({
    backgroundVideo,
    backgroundImage,
    feature,
}) => {
    const data = useStaticQuery(graphql`
        query homeEvents {
            siteFields {
                events(first: 3, after: null) {
                    edges {
                        node {
                            title
                            content
                            link
                            slug
                            date
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            eventFields {
                                date
                                displayDate
                                excerpt
                                slideshowImage {
                                    mediaItemUrl
                                }
                            }
                            types {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const events = data.siteFields.events.edges

    const isMobile = useIsMobile()

    const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    },[])

    const {
        subtitle: featureSubtitle,
        title: featureTitle,
        image: featureImage,
        link: featureLink,
    } = feature

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
        cellAlign: 'left',
    }

    const cross = (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path id="Concrete-Texture-5" fill="#15104d" d="M500,366.57,383.43,250,500,133.43,366.57,0,250,116.57,133.43,0,0,133.43,116.56,250,0,366.57,133.43,500,250,383.43,366.57,500Z"/></svg>
    )

    const handleScroll = (id) => {
        const element = document.getElementById(id)

        const offsetAmount = isMobile ? 30 : 120
        const y = element.getBoundingClientRect().top + window.pageYOffset - offsetAmount

        window.scrollTo({ top: y, behavior: 'smooth' })

        return false
    }

    return (
        <div className="HomeHero-cont" ref={ref => elementRef.current = ref}>
            <div className="HomeHero-intro-cont" style={{ backgroundImage: `url(${backgroundImage ? backgroundImage.mediaItemUrl : ''})` }}>
                { backgroundVideo &&   
                    <video autoPlay muted loop playsInline>
                        <source src={backgroundVideo.mediaItemUrl} type="video/mp4" />
                    </video>
                }                
                <div className='container'>
                    <div className="HomeHero-intro">
                        <h1>
                            {cross}
                            <div onClick={() => handleScroll('experience')}>
                                Experience
                                <span>.</span>
                            </div>
                            {cross}
                            <div onClick={() => handleScroll('eat-and-drink')}>
                                Eat
                                <span>.</span>
                            </div>
                            {cross}
                            <div onClick={() => handleScroll('eat-and-drink')}>
                                Drink
                                <span>.</span>
                            </div>
                            {cross}
                            <div onClick={() => handleScroll('stay')}>
                                Stay
                                <span>.</span>
                            </div>
                            {cross}
                        </h1>
                        <h1>at Pentridge.</h1>
                    </div>
                </div>
            </div>
            <div className="HomeHero-main">
                <div className={`container${!featureLink ? ' nofeature' : ''}`}>
                    <div className="HomeHero-events">
                        { events &&
                            <Slider
                                className={'HomeHero-events-slideshow'}
                                options={flickityOptions}
                            >
                                {events.map((event, index) => {
                                    const {
                                        title,
                                        slug,
                                        featuredImage,
                                        eventFields,
                                    } = event.node

                                    const {
                                        excerpt,
                                        slideshowImage,
                                    } = eventFields

                                    const eventImage = slideshowImage ? slideshowImage.mediaItemUrl : featuredImage ? featuredImage.node.mediaItemUrl : ''

                                    return (
                                        <div className="HomeHero-events-slideshow-item" key={index}>
                                            <div className="HomeHero-events-slideshow-item-details">
                                                <Link 
                                                    to={'/whats-on/'}
                                                    className="HomeHero-events-slideshow-item-subtitle"
                                                >
                                                    <span>
                                                        What's on
                                                    </span>
                                                </Link>
                                                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                                                { excerpt &&
                                                    <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                                                }
                                                <Link 
                                                    to={'/whats-on/' + slug}
                                                    className="HomeHero-events-slideshow-item-link"
                                                >
                                                    <span className={'button'}>
                                                        View event
                                                    </span>
                                                </Link>
                                            </div>
                                            { eventImage &&
                                                <div
                                                    className="HomeHero-events-slideshow-item-image"
                                                    style={{
                                                        backgroundImage: `url(${eventImage})`,
                                                    }}
                                                />
                                            }
                                        </div>
                                    )
                                })}
                            </Slider>
                        }
                    </div>
                    { featureLink &&
                        <div className="HomeHero-feature">
                            <Link to={ featureLink.url }>
                                { featureSubtitle &&
                                    <div
                                        className="HomeHero-feature-subtitle"
                                        dangerouslySetInnerHTML={{ __html: featureSubtitle }}
                                    />
                                }
                                { featureTitle &&
                                    <h2 dangerouslySetInnerHTML={{ __html: featureTitle }} />
                                }
                                { featureImage &&
                                    <img src={ featureImage.mediaItemUrl } />
                                }
                                <span className="button">
                                    View directory
                                </span>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
