import { useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash'

import React from 'react'
import Hero from 'components/hero'
import TextBlock from 'components/textBlock'
import FeatureBoxes from 'components/featureBoxes'
import ImageWithCopy from 'components/imageWithCopy'
import MultipleImagesWithCopy from 'components/multipleImagesWithCopy'
import DirectoryShowcase from 'components/directoryShowcase'
import DirectorySlideshow from 'components/directorySlideshow'
import DirectoryFeature from 'components/directoryFeature'
import BlogGrid from 'components/blogGrid'
import InstagramFeed from 'components/instagramFeed'
import Subscribe from 'components/subscribe'
import ImageSlideshow from 'components/imageSlideshow'
import Partners from 'components/partners'
import Masterplan from 'components/masterplan'
import DevelopmentShowcase from 'components/developmentShowcase'
import SubscribeFeature from 'components/subscribeFeature'
import Contacts from 'components/contacts'
import DirectoryGrid from 'components/directoryGrid'
import Timeline from 'components/timeline'
import NewsSlideshow from 'components/newsSlideshow'
import TwoColumnTextBlock from 'components/twoColumnTextBlock'
import HomepageFeatureSlideshow from 'components/homepageFeatureSlideshow'
import TimelineQuickLinks from 'components/timelineQuickLinks'
import GenericForm from 'components/genericForm'
import FullWidthImage from 'components/fullWidthImage'
import ChristmasPrizeForm from 'components/christmasPrizeForm/christmasPrizeForm'
import ChristmasPrizeChecker from 'components/christmasPrizeForm/christmasPrizeChecker'
import FeatureStrip from 'components/featureStrip'
import HomeHero from 'components/homeHero'
import DirectorySection from 'components/directorySection'
import Cta from 'components/cta'

const components = {
    Hero,
    TextBlock,
    FeatureBoxes,
    ImageWithCopy,
    MultipleImagesWithCopy,
    DirectoryShowcase,
    DirectorySlideshow,
    DirectoryFeature,
    BlogGrid,
    InstagramFeed,
    Subscribe,
    ImageSlideshow,
    Partners,
    Masterplan,
    DevelopmentShowcase,
    SubscribeFeature,
    Contacts,
    DirectoryGrid,
    Timeline,
    NewsSlideshow,
    TwoColumnTextBlock,
    HomepageFeatureSlideshow,
    TimelineQuickLinks,
    GenericForm,
    FullWidthImage,
    ChristmasPrizeForm,
    ChristmasPrizeChecker,
    FeatureStrip,
    HomeHero,
    DirectorySection,
    Cta,
}

export default ({ 
    content,
    location
}) => {
    const componentRefs = useRef([])
    const [visibleIndices, setVisibleIndices] = useState([])
    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null
            
            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if(offset < (windowHeight / 1.5)) {
                _visibleIndices.push(index) 
            }
        })

        if(!isEqual(_visibleIndices,visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const addComponent = (ref, index) => {
        const _componentRefs =  [...componentRefs.current]

        _componentRefs[index] = ref

        componentRefs.current = _componentRefs
    }

    const slug = location ? location.pathname.replace('/','') : ''

    return (
        <div className={`page-${ slug }`}>
            { content.flexibleContent.map((row, index) => {
                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_','')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                let className = `${Component} ${ isScrolledIntoView ? 'scrolled-into-view' : '' }`

                return (
                    <section 
                        className={className} 
                        ref={ref => addComponent(ref, index)} 
                        key={index}
                    >
                        <Tag {...row} location={location}/>
                    </section>
                )
            }) }
        </div>
    )
}
