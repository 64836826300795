import React, { useContext, useEffect, useRef } from "react"
import { MainContext } from "context/context"
import { useIsMobile } from 'utils'

export default ({ 
    title, 
    size,
    mobileBackgroundImage,
    backgroundImage,
    featureColour,
    useCompetitionLayout,
    imageOverlay,
    removeOverlay,
}) => {

    const isMobile = useIsMobile()

    const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            !useCompetitionLayout && addInvertedHeaderSection(elementRef.current)
        }
    },[])

    let heroContVar = `Hero-cont ${ useCompetitionLayout ? 'competition-layout' : ''}`

    if (featureColour) {
        heroContVar += ` bg ${ featureColour }`
    }

    if (backgroundImage) {
        heroContVar += ' hasBG'
    }

    if (size === 'small') {
        heroContVar += ' small'
    }

    if (removeOverlay) {
        heroContVar += ' removeOverlay'
    }

    const backgroundImageVar = isMobile ? mobileBackgroundImage ? mobileBackgroundImage.mediaItemUrl : '' : backgroundImage ? backgroundImage.mediaItemUrl : ''

    return (
        <div className={ heroContVar } style={{ backgroundImage: `url(${backgroundImageVar})` }} ref={ref => elementRef.current = ref}>
            { title && <div dangerouslySetInnerHTML={{ __html: title }} /> }
            { imageOverlay &&
                <div className="Hero-imageOverlay container">
                    <img src={ imageOverlay.mediaItemUrl } />
                </div>
            }
        </div>
    )
}
