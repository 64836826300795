import React, { useCallback } from "react"
import { useIsMobile } from 'utils'

export default ({ title1, title2 }) => {

    const isMobile = useIsMobile()

    const handleScroll = useCallback((id) => {

        const element = document.getElementById(id)

        const offsetHeight = isMobile ? 151 : 100

        const y = element.getBoundingClientRect().top + window.pageYOffset - offsetHeight

        window.scrollTo({top: y, behavior: 'smooth'})

        return false

    }, [isMobile])

    return (

        <>
            <div onClick={() => handleScroll('Timeline-thehistoryofpentridge')} onKeyDown={() => handleScroll('Timeline-thehistoryofpentridge')} role="button" tabIndex={ -1 }>
                <span dangerouslySetInnerHTML={{ __html: title1 }} />
            </div>
            <div onClick={() => handleScroll('Timeline-developmentandrestoration')} onKeyDown={() => handleScroll('Timeline-developmentandrestoration')} role="button" tabIndex={ -2 }>
                <span dangerouslySetInnerHTML={{ __html: title2 }} />
            </div>
        </>
        
    )
}
