import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import DirectoryItem from 'components/directoryItem'

export default ({ title, location }) => {
    const data = useStaticQuery(graphql`
        query {
            siteFields {
                places: places(first: 1000, after: null) {
                    edges {
                        node {
                            databaseId
                            title
                            link
                            slug
                            content
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            directoryTypes {
                                nodes {
                                    name
                                    slug
                                }
                            }
                            directoryFields {
                                excerpt
                                logo {
                                    mediaItemUrl
                                }
                                logoBackgroundColour
                                logoCoverTile
                                openingHours {
                                    day
                                    hours
                                }
                            }
                        }
                    }
                }
                types: directoryTypes {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
    `)

    const [activeType, setActiveType] = useState(null)
    const [activeName, setActiveName] = useState(null)
    const [gridVisible, setGridVisible] = useState(true)

    const directoryType = location ? location.pathname.split('/')[2] : null
    
    const directory = data ? data.siteFields.places.edges : null
    const types = data ? data.siteFields.types.nodes : null

    useEffect(() => {
        if (directoryType) {
            const foundType = types.find(type => type.slug === directoryType)
            if (types && foundType) {
                setActiveType(directoryType)
                setActiveName(foundType.name)
            }
        }
    }, [])

    const toggleType = (slug, name) => {
        setGridVisible(false)
        setActiveName(name)
        setTimeout(() => {
            if (slug === activeType) {
                setActiveType(null)
                setActiveName(null)
                window.history.pushState('page2', 'Title', '/directory/' );
            } else  {
                setActiveType(slug)
                window.history.pushState('page2', 'Title', '/directory/' + slug);
            }
            setGridVisible(true)
        }, 300)
    }

    const filteredPlaces = (() => {
        if (activeType) {
            return directory.filter(({node})=> {
                return node.directoryTypes.nodes.some(({slug}) => slug === activeType)
            })
        }
        return directory
    })()

	return (
        <>
            <div className="DirectoryGrid-hero">
                <div className="container">
                    { title && <h1 dangerouslySetInnerHTML={{ __html: title }} /> }
                    <ul className="DirectoryGrid-hero-typeFilter">
                        { types && types.map(({name, slug}, index) => { 
                            const isActive = activeName === name

                            return (
                                <li key={index} className={isActive ? "active" : ""}>
                                    <button className={"directory-button"} onClick={() => toggleType(slug, name)}>
                                        {name && <span dangerouslySetInnerHTML={{ __html: name }} />}
                                    </button>
                                </li> 
                            )
                        })}
                    </ul>
                    <button 
                        className={"directory-button all" + ` ${activeName ? 'visible' : ''}`} 
                        onClick={() => toggleType(activeType, activeName)}
                    >
                        <span dangerouslySetInnerHTML={{ __html: "Show all" }} />
                    </button>
                </div>
            </div>
            <div className={`directoryGrid ${gridVisible ? 'visible' : ''}`}>
                <div className="container">
                    <ul>
                        {filteredPlaces.map((row) => {
                            const {
                                node,
                            } = row

                            return (
                                <li key={node.databaseId}>
                                    <DirectoryItem item={ node } showHours='true' showLink='true' />
                                </li>
                            )   
                            
                        })}
                    </ul>
                </div>
            </div>
        </>
	)
}
