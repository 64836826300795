import React, { useState } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default ({ title, copy, image }) => {

    const [emailValue, setEmailValue] = useState('')

    const [email, setEmail] = useState('')

    const [formMsg, setFormMessage] = useState(copy)

    const handleSubmit = e => {

        e.preventDefault()

        addToMailchimp(email)
        .then(data => {
            
            setFormMessage( '<p>' + data.msg + '<p>')

            if (data.result === 'success') {
                setEmail('')
                setEmailValue('')
            }

        })
        .catch(() => {})
    }

    const handleEmailChange = (event) => {
        
        setEmail(event.currentTarget.value)
        setEmailValue(event.currentTarget.value)

    }

    return (

        <div className="SubscribeFeature-cont yellow bg">

            <div className="container">

                <div className="SubscribeFeature-image-cont">

                    <div className="SubscribeFeature-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />

                    <svg width="0" height="0">
                        <defs>
                            <clipPath id="clipPath-x" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                <path d="M500,366.57,383.43,250,500,133.43,366.57,0,250,116.57,133.43,0,0,133.43,116.56,250,0,366.57,133.43,500,250,383.43,366.57,500Z" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>

                <div className="SubscribeFeature-main">

                    <div className="SubscribeFeature-text">

                        <div dangerouslySetInnerHTML={{ __html: title }} />

                        <form onSubmit={ handleSubmit }>

                            <input type="email" placeholder="hello@email.com" value={ emailValue } onChange={ handleEmailChange } />

                            <button type="submit" className="button border">Subscribe</button>

                        </form>
                        
                        <div dangerouslySetInnerHTML={{ __html: formMsg }} />

                    </div>
                
                </div>

            </div>

        </div>
        
    )
}
