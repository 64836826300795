import React, { useState, useEffect } from "react"
import Link from 'components/link'

export default ({ 
    image,
    imageLink,
    copy, 
    featureColour, 
    link, 
    imageSize, 
    imageMask, 
    orientation, 
    imageFirst, 
    backgroundColour, 
    fullWidth,
    textBackground,
    featureVideo,
}) => {
    const containerClass = `container ${ imageFirst ? 'reverse' : '' } ${ imageSize === 'large' ? 'large' : '' } ${ orientation || '' } ${ imageMask === 'none' ? 'no-mask' : '' } ${ fullWidth ? 'full-width' : '' } ${ textBackground ? 'textBG textBG-' + textBackground : '' }`
    const _window = typeof window !== 'undefined' ? window : null
    
    const [isOurStory, setIsOurStory] = useState(false)

    useEffect(() => {
        if (!isOurStory) {
            setIsOurStory(_window?.location.href.includes('our-story'))
        }
    }, [])

    return (
        <div className={ `${backgroundColour  ? 'ImageWithCopy-cont ' + backgroundColour : 'ImageWithCopy-cont'} ${isOurStory ? 'is-our-story' : ''}` }>
            <div className={containerClass}>
            <div className={ 'ImageWithCopy-copy ' + featureColour }>
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                    { link ?
                        link.url.startsWith('http') ?
                            <a href={ link.url } target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: link.title }} className="button" />
                        :
                            <Link to={ link.url } dangerouslySetInnerHTML={{ __html: link.title }} className="button" />
                        : ''
                    }
                </div>
                <div className='ImageWithCopy-image-cont'>
                    <div 
                        className={ 'ImageWithCopy-image ' + imageMask } 
                        style={{ backgroundImage: `url(${image.mediaItemUrl})` }} 
                    >
                        { imageLink && <a href={ imageLink.url } target={ imageLink.target } dangerouslySetInnerHTML={{ __html: imageLink.title }} /> }
                        { featureVideo &&
                            <video autoPlay muted loop playsInline>
                                <source src={featureVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                        }
                    </div>
                    <svg width="0" height="0">
                        <defs>
                            <clipPath id="clipPath-x" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                <path d="M500,366.57,383.43,250,500,133.43,366.57,0,250,116.57,133.43,0,0,133.43,116.56,250,0,366.57,133.43,500,250,383.43,366.57,500Z" />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg width="0" height="0">
                        <defs>
                            <clipPath id="clipPath-arch1" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                <path d="M500,550V250.33c0-333.77-500-333.77-500,0V550Z"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg width="0" height="0">
                        <defs>
                            <clipPath id="clipPath-arch2" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                <path id="Path_158" data-name="Path 158" d="M0,550V142.31s-2.9-40,38.46-60.67S198.48,0,250.08,0,420.22,60.76,461.57,81.64,500,142.4,500,142.4V550Z" transform="translate(0)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg width="0" height="0">
                        <defs>
                            <clipPath id="clipPath-circle" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                <circle cx="250" cy="250" r="250"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>  
        </div>
    )
}
