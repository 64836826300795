import React from 'react'

import Link from 'components/link'

export default ({
    title,
    copy,
    image,
    link,
}) => {
    return (
        <div className='container'>
            <div className='Cta-inside'>
                { link &&
                    <div className={'Cta-button-container'}>
                        <Link
                            to={ link.url }
                            dangerouslySetInnerHTML={{ __html: link.title }}
                            className="button lowercase"
                        />
                    </div>
                }
                <div className='Cta-content'>
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    <div className='Cta-copy' dangerouslySetInnerHTML={{ __html: copy }} />
                </div>
                { image &&
                    <div className='Cta-image'>
                        <img src={image.mediaItemUrl} />
                    </div>
                }
            </div>
        </div>
    )
}
