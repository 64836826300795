import React, { useState } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default ({ 
    title,
    copy,
    image,
    backgroundColour,
    customMailchimpList,
}) => {
    const [emailValue, setEmailValue] = useState('')

    const [email, setEmail] = useState('')

    const [formMsg, setFormMessage] = useState(copy)

    const handleSubmit = e => {
        e.preventDefault()

        if (customMailchimpList) {
            addToMailchimp(email, {}, customMailchimpList).then(data => {
                setFormMessage( '<p>' + data.msg + '<p>')
    
                if (data.result === 'success') {
                    setEmail('')
                    setEmailValue('')
                }
            }).catch(() => {})
        } else {
            addToMailchimp(email).then(data => {
                setFormMessage( '<p>' + data.msg + '<p>')
    
                if (data.result === 'success') {
                    setEmail('')
                    setEmailValue('')
                }
            }).catch(() => {})
        }
    }

    const handleEmailChange = (event) => {
        
        setEmail(event.currentTarget.value)
        setEmailValue(event.currentTarget.value)

    }

    return (

        <div className={ backgroundColour ? 'Subscribe-cont ' + backgroundColour : 'Subscribe-cont' }>

            <div className="container">

                <div className="Subscribe-main">

                    <div className="Subscribe-text">

                        { title ? <div dangerouslySetInnerHTML={{ __html: title }} /> : null }

                        { formMsg ? <div dangerouslySetInnerHTML={{ __html: formMsg }} /> : null }

                        <form onSubmit={ handleSubmit }>

                            <input type="email" placeholder="Enter your email address" value={ emailValue } onChange={ handleEmailChange } />

                            <button type="submit" className="button border">Subscribe</button>

                        </form>

                    </div>
                
                </div>

                { image ?

                    <div className="Subscribe-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />

                : null }

            </div>

        </div>
        
    )
}
