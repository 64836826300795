import React from "react"

export default ({ images, title, copy, backgroundColour }) => {

    return (

        <div className={ backgroundColour ? 'Partners-cont ' + backgroundColour : 'Partners-cont' }>

            <div className="container">
                
                <div className="Partners-text">

                    <div className="Partners-title">

                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    
                    </div>

                    <div className="Partners-copy" dangerouslySetInnerHTML={{ __html: copy }} />
                    
                </div>

                <ul>

                    {images.map((row, index) => {

                        const {
                            image,
                            url
                        } = row

                        return (

                            <li key={index}>
                                
                                { url ?
                                    <a href={url} target="_blank" rel="noopener noreferrer" className="Partners-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }}>
                                        { image.title }
                                    </a>
                                :
                                    <div className="Partners-image"  style={{ backgroundImage: `url(${image.mediaItemUrl})` }} title={ image.title } />
                                }

                            </li>

                        )

                    })}

                </ul>
                
            </div>
                
        </div>
        
    )
}
