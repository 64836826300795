import React, { useRef, useEffect } from "react"
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'
import { useIsMobile } from 'utils'

export default ({ slide, title, backgroundColour }) => {

    const sliderRef = useRef(null)

    const isMobile = useIsMobile()

    let pageDots = false

    if (isMobile) {
        pageDots = true
    }

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: true,
        pageDots,
        cellAlign: 'left',
    }

    useEffect(() => {
        setTimeout(() => {
            sliderRef.current.resize()
        }, 600)
    }, [])

    return (

        <div className={ backgroundColour ? 'ImageSlideshow-cont ' + backgroundColour : 'ImageSlideshow-cont' }>

            <div className="container">
                
                <div className="ImageSlideshow-title">

                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    
                </div>

                <Flickity
                    className={'ImageSlideshow-slideshow'}
                    options={flickityOptions}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    {slide.map((row, index) => {

                        const {
                            title,
                            copy,
                            image
                        } = row

                        return (

                            <div className="ImageSlideshow-item" key={index}>
                                
                                <div className="ImageSlideshow-item-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />

                                <div className="ImageSlideshow-item-copy">
                                    
                                    <div className="ImageSlideshow-item-title-cont">
                                    
                                        <div className="ImageSlideshow-item-title" dangerouslySetInnerHTML={{ __html: title }} />
                                    
                                    </div>

                                    <div className="ImageSlideshow-item-text" dangerouslySetInnerHTML={{ __html: copy }} />

                                </div>

                            </div>
                            
                        )
                        
                    })}
                </Flickity>
                
            </div>
                
        </div>
        
    )
}
