import React, { useState } from 'react'
import Form from 'components/form'

import { cloneDeep } from 'lodash'
import Swal from 'sweetalert2'
import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import Link from 'components/link'

import addToMailchimp from 'gatsby-plugin-mailchimp'

export default ({
    title,
    copy,
    form
}) => {
    const [fieldValues, setFieldValues] = useState({})

    const [submitForm] = useMutation(SUBMIT_FORM)
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (inputKey, value) => {
        const _fieldValues = cloneDeep(fieldValues)

        _fieldValues[inputKey] = value

        setFieldValues(_fieldValues)
    }

    const handleAddToMailChimp = email => {
        addToMailchimp(email).then(data => {
            console.log(data)
        }).catch(e => {
            console.log(e)
        })
    }

    const handleSubmit = data => {
        const json = JSON.stringify(data)

        let signUpKey = null
        let emailKey = null

        Object.keys(data).forEach(key => {
            const _key = key.toLowerCase()

            // console.log('_key')
            // console.log(_key)

            if (_key.indexOf('sign up') !== -1 || _key.indexOf('subscribe') !== -1 || _key.indexOf('by entering') !== -1 || _key.indexOf('choose your prize') !== -1) {
                signUpKey = key
            }

            if(_key.indexOf('email') !== -1){
                emailKey = key
            }
        })

        setIsLoading(true)

        if (signUpKey && data[signUpKey] === true) {
            handleAddToMailChimp(data[emailKey])
        } else if ( signUpKey && ( data[signUpKey] === 'cinemaHire' || data[signUpKey] === 'hamper' ) ) {
            handleAddToMailChimp(data[emailKey])
        }

        submitForm({
            variables: {
                data: json,
                type: 'COMPETITION'
            },
        }).then(response => {
            setIsLoading(false)

            Swal.fire({
                icon: 'success',
                title: 'Thanks for that',
                text: `We've received your submission.`,
            })

            setFieldValues({})
        }).catch(error => {
            console.log(error)

            setIsLoading(false)
        })
    }

    return (
        <div className={'container'}>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
            <div className={'contact-form'}>
                <Form
                    form={form}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    fieldValues={fieldValues}
                    handleChange={handleChange}
                />
            </div>
        </div>
    )
}