import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import NewsItem from 'components/newsItem'

import { useIsMobile } from 'utils'

import Slider from 'components/slider'

export default ({ 
    title, 
    category, 
    backgroundColour 
}) => {
    const data = useStaticQuery(graphql`
        query newsQuery {
            siteFields {
                posts (
                    first: 999
                ) {
                    edges {
                        node {
                            title
                            link
                            slug
                            date
                            content(format: RENDERED)
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            newsFields {
                                date
                                excerpt
                            }
                            categories {
                                edges {
                                    node {
                                        name
                                        slug
                                    }
                                }
                            }
                            tags {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const { edges: posts } = data.siteFields.posts

    const _allPosts = posts.map(post => post.node)

    let _postsByCats = []

    _allPosts.forEach(postRef => {
        if (postRef.categories.edges[0]) {
            if (postRef.categories.edges[0].node.slug === category[0].slug) {
                _postsByCats.push(postRef)
            }
        }
    })

    const sliderRef = useRef(null)

    const isMobile = useIsMobile()

    let pageDots = false

    if (isMobile) {
        pageDots = true
    }

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: true,
        pageDots,
        cellAlign: 'left',
    }

    useEffect(() => {
        setTimeout(() => {
            sliderRef.current.resize()
        }, 600)
    }, [])
    
    return (
        <div className={ backgroundColour ? 'NewsSlideshow-cont ' + backgroundColour : 'NewsSlideshow-cont' }>
            <div className="container">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                <Slider
                    className={'NewsSlideshow-slideshow'}
                    options={flickityOptions}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    {_postsByCats.map((row, index) => {
                        return (
                            <div className="NewsSlideshow-item" key={index}>
                                <NewsItem item={ row } />
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}
