import React from 'react'

export default ({options, label, handleChange}) => {

    const optionsArray = options.split('\n')

    const _options = []

    optionsArray.forEach(option => {
        const valueArray = option.split(' : ')

        _options.push({
            value: valueArray[0],
            label: valueArray[1]
        })
    })

    const radioLabel = label.split(" ").join("").replace(":","").toLowerCase()

    return (
        <div className="radiobuttons">
            <label dangerouslySetInnerHTML={{ __html: label }} />
            {_options.map((option, index) => {
                return (
                    <div key={ index }>
                        <label htmlFor={ option.value }>
                            <input type="radio" name={ radioLabel } id={ option.value } value={ option.value } onChange={e => handleChange(e.target.value)} />
                            <em />
                            <span dangerouslySetInnerHTML={{ __html: option.label }} />
                        </label>
                    </div>
                )
            })}
        </div>
    )
}