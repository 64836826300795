import React, { useRef, useEffect } from "react"
import TitleWrapper from 'components/titleWrapper'
import DirectoryItem from 'components/directoryItem'
import Slider from 'components/slider'
import { useIsMobile } from 'utils'
import Link from 'components/link'

export default ({
    title,
    directory,
    events,
    showHours,
    featureColour,
    backgroundColour,
    type,
    hide,
}) => {

    if (hide) {
        return null
    }

    const sliderRef = useRef(null)

    const isMobile = useIsMobile()

    let pageDots = false
    let prevNextButtons = true

    if (isMobile) {
        pageDots = true
        prevNextButtons = false
    }

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons,
        pageDots,
        cellAlign: 'left',
        autoPlay: type === 'directory' ? false : true
    }

    useEffect(() => {
        setTimeout(() => {
            sliderRef.current.resize()
        }, 600)
    }, [])

    const _directory = directory ? directory.length < 3 ? directory.concat(directory) : directory : []
    const _events = events ? events.length < 3 ? events.concat(events) : events : []

    return (
        <div className={`DirectorySlideshow-cont ${backgroundColour || ''} ${ type }`}>
            <TitleWrapper
                title={title}
                featureColour={featureColour}
                button={
                    <Link
                        to={`/${ type === 'directory' ? 'directory' : 'whats-on' }`}
                        dangerouslySetInnerHTML={{ __html: type === 'directory' ? 'Directory' : `What's On` }}
                        className="button lowercase"
                    />
                }
            >
                <Slider
                    className={ 'DirectorySlideshow-slideshow dots' + ( directory ? directory.length : '' ) }
                    options={flickityOptions}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    {(type === 'directory' ? _directory : _events).map((row, index) => {
                        const {
                            place
                        } = row

                        return (
                            ( row.place || row.event ) &&
                                <div className={ 'DirectorySlideshow-item ' + type } key={index}>
                                    {type === 'directory' ?
                                        <DirectoryItem
                                            item={place}
                                            showHours={showHours}
                                        />
                                    :
                                        (() => {
                                            const {
                                                title,
                                                slug,
                                                featuredImage,
                                            } = row.event

                                            return (
                                                <Link 
                                                    to={'/whats-on/' + slug}
                                                    key={index} 
                                                    className={'directoryItem'}
                                                >
                                                    <div className="directoryItem-image-cont">
                                                        <div className="bg" style={{
                                                            backgroundImage: `url(${ featuredImage && featuredImage.node.mediaItemUrl })`
                                                        }} />
                                                        <div className="directoryItem-image ">
                                                            <div className="directoryItem-logo" style={{

                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="directoryItem-text">
                                                        <div className={ 'directoryItem-title-item ' + type }>
                                                            <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                                            <span className={'button'}>
                                                                View event
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )

                                        })()
                                    }
                                </div>
                        )
                    })}
                </Slider>
            </TitleWrapper>
        </div>
    )
}
