import React from "react"
import Link from 'components/link'
import { DevelopmentStatus } from 'utils'

export default ({ item }) => {

    const {
        featuredImage,
        slug,
        developmentFields,
        title
    } = item

    const _featuredImage = featuredImage ? featuredImage.node.mediaItemUrl : ''

    return (

        <Link to={'/developments/' + slug} className="developmentItem">

            <div className="developmentItem-status">

                <DevelopmentStatus value={developmentFields.status} />

            </div>

            <div className="developmentItem-image-cont">

                <div className="developmentItem-image" style={{ backgroundImage: `url(${_featuredImage})` }} />

            </div>

            <div className="developmentItem-text">

                <div className="developmentItem-title">

                    <h2 dangerouslySetInnerHTML={{ __html: title }} />

                </div>

                <button className="button">Read more</button>

            </div>

        </Link>

    )
}
