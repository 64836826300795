import React from "react"
import TitleWrapper from 'components/titleWrapper'
import Link from 'components/link'

export default ({ title, copy, place, linkText, featureColour, backgroundColour }) => {

    const {
        slug,
        featuredImage,
        directoryFields
    } = place

    const _featuredImage = featuredImage ? featuredImage.node.mediaItemUrl : ''
    const logo = directoryFields.logo ? directoryFields.logo.mediaItemUrl : ''
    const hours = directoryFields.openingHours
    const linkTextVar = linkText ? linkText : ''
    
    return (

        <div className={ backgroundColour ? 'DirectoryFeature-cont ' + backgroundColour : 'DirectoryFeature-cont' }>

            <div className="container">
                
                <Link to={ '/directory/' + slug } dangerouslySetInnerHTML={{ __html: linkTextVar }} className="button border" />

            </div>

            <TitleWrapper
                title={ title }
                copy={ copy }
                hours={ hours }
                featureColour={ featureColour }
            >

                <div className="DirectoryFeature-image-cont">

                    <Link to={ '/directory/' + slug }>
                    
                        <div className="DirectoryFeature-image" style={{ backgroundImage: `url(${_featuredImage})` }}>

                            <div className="DirectoryFeature-logo" style={{ backgroundImage: `url(${logo})` }} />

                        </div>

                    </Link>

                </div>

            </TitleWrapper>

        </div>

    )
}
