import React, { useEffect } from "react"

import ChristmasPresents from '../../images/christmas-presents.svg'
import ChristmasTree1 from '../../images/christmas-tree-1.svg'

export default ({
    result,
    isWinner,
    hasChecked,
}) => {
    if ( !hasChecked || !isWinner || !result ) {
        return false
    }

    const _window = typeof window !== 'undefined' ? window : null
    
    useEffect(() => {
        if ( _window ) {
            _window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    },[hasChecked, result, isWinner])

    const {
        result: _result,
        name,
        prize,
        prizeImage,
        code,
    } = result

    return (
        <div className={`ChristmasPrizeResult winner`}>
            <div className="container">
                { hasChecked && isWinner && _result === 'winner' ?
                    <div className="ChristmasPrizeResult-content">
                        <h1 dangerouslySetInnerHTML={{ __html: 'Congratulations<br /><span>' + name + '!</span>' }} />
                        <div dangerouslySetInnerHTML={{ __html: 'You have won: ' + prize }} />
                        { ( prizeImage !== "false" ) && <img src={ prizeImage } /> }
                        <div className="ChristmasPrizeResult-code">
                            <span dangerouslySetInnerHTML={{ __html: 'Your winning code:' }} />
                            <div dangerouslySetInnerHTML={{ __html: code }} />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: '<span>How to redeem:</span> Please present this unique code at the store to redeem. All prizes much be redeemed within 1 month of issue.' }} />
                        <img src={ ChristmasPresents } />
                    </div>
                :
                    <div className="ChristmasPrizeResult-content">
                        <h1 dangerouslySetInnerHTML={{ __html: '<span>Sorry!</span>' }} />
                        <div dangerouslySetInnerHTML={{ __html: 'You can only enter once. Be quick and try again tomorrow.' }} />
                        <img src={ ChristmasTree1 } />
                    </div>
                }
            </div>
        </div>
    )
}
