import React from 'react'

export default ({
    inputKey,
    handleChange
}) => {
    return (
        <label htmlFor={inputKey}>
            <span dangerouslySetInnerHTML={{ __html: inputKey }} />
            <textarea id={inputKey}
                onChange={e => handleChange(e.target.value)}
            />
        </label>
    )
}