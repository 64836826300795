import React, { useState, useRef, useEffect } from "react"
import Slider from 'components/slider'

import { useIsMobile } from 'utils'
import { nl2br } from 'utils'

export default ({ title, milestone, imagesFirst, backgroundColour }) => {
    const containerRef = useRef(null)
    const yearRefs = useRef([])
    const milestoneRefs = useRef([])

    const isMobile = useIsMobile()

    const [milestonesHeight, setMilestonesHeight] = useState(0)

    const [activeYear, setActiveYear] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            _setActiveYear(0)
        },500)
    }, [])

    const _setActiveYear = index => {
        setActiveYear(index)

        const container = containerRef.current
        const element = yearRefs.current[index]
        const milestone = milestoneRefs.current[index]

        const milestoneHeight = milestone.getBoundingClientRect().height
        setMilestonesHeight(milestoneHeight)

        container.scrollTo({ top: (index * element.getBoundingClientRect().height), behavior: 'smooth' })

        return false
    }

    const sliderRef = useRef(null)

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: 'left',
        autoPlay: 2000,
        fade: true,
    }

    return (
        <div
            id={"Timeline-" + title.toLowerCase().replace(/\s/g, '')}
            className={backgroundColour ? 'Timeline-cont ' + backgroundColour : 'Timeline-cont'}
        >
            <div className="container">
                { isMobile ?
                    <h1>
                        { title }
                    </h1>
                : null }
                <div className="Timeline-years-cont">
                    <div
                        className="Timeline-years"
                        ref={ref => containerRef.current = ref}
                    >
                        {milestone.map((row, index) => {
                            const {
                                year
                            } = row

                            return (
                                <div
                                    key={index}
                                    onClick={() => _setActiveYear(index)}
                                    onKeyDown={() => _setActiveYear(index)}
                                    ref={ref => yearRefs.current[index] = ref}
                                    role="button"
                                    tabIndex={index}
                                    className={activeYear === index ? 'active' : ''}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: year }} />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 500 500"
                                    >
                                        <path
                                            fill="#ea6852"
                                            d="M500,366.57,383.43,250,500,133.43,366.57,0,250,116.57,133.43,0,0,133.43,116.56,250,0,366.57,133.43,500,250,383.43,366.57,500Z"
                                        />
                                    </svg>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div
                    className={`Timeline-main ${imagesFirst ? 'reverse' : ''}`}
                >
                    { !isMobile ?
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    : null }
                    <div
                        className={'Timeline-main-inner'}
                        style={{
                            height: milestonesHeight ? milestonesHeight + 'px' : undefined
                        }}
                    >
                        {milestone.map((row, index) => {
                            const {
                                title,
                                copy,
                                images
                            } = row

                            return (
                                <div
                                    key={index}
                                    className={`Timeline-milestone ${activeYear === index ? 'active' : ''}`}
                                    ref={ref => milestoneRefs.current[index] = ref}
                                >
                                    <div>
                                        <div className="Timeline-milestone-images">
                                            {images &&
                                                images.length > 1 ?
                                                <Slider
                                                    className='Timeline-milestone-slideshow'
                                                    options={flickityOptions}
                                                    flickityRef={ref => sliderRef.current = ref}
                                                    contain="false"
                                                >
                                                    {images.map((row, index) => {
                                                        const {
                                                            image,
                                                            imageCaption,
                                                            orientation
                                                        } = row

                                                        return (
                                                            <div className="Timeline-milestone-slideshow-item" key={index}>
                                                                <>
                                                                    <div
                                                                        className={orientation ? 'Timeline-milestone-image ' + orientation : 'Timeline-milestone-image'}
                                                                        style={{ backgroundImage: `url(${image.mediaItemUrl})` }}
                                                                    />
                                                                    {imageCaption && <span dangerouslySetInnerHTML={{ __html: imageCaption }} className={orientation} />}
                                                                </>
                                                            </div>
                                                        )
                                                    })}
                                                </Slider>
                                                :
                                                <div key={index}>
                                                    <div
                                                        className={images[0].orientation ? 'Timeline-milestone-image ' + images[0].orientation : 'Timeline-milestone-image'}
                                                        style={{ backgroundImage: `url(${images[0].image.mediaItemUrl})` }}
                                                    />
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: images[0].imageCaption }}
                                                        className={images[0].orientation}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="Timeline-milestone-copy">
                                            <h2 dangerouslySetInnerHTML={{ __html: title }} />
                                            <div dangerouslySetInnerHTML={{ __html: nl2br(copy) }} />
                                            {activeYear + 1 < milestone.length ?
                                                <button
                                                    onClick={() => _setActiveYear(activeYear + 1)}
                                                    className="next button"
                                                >
                                                    Next
                                                </button>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
