import React, { useEffect, useState } from 'react'

export default ({
    handleClickBuilding,
    activePosition,
    active,
    activeSectionId,
    forceTransitionTransformOrigin
}) => {
    const [_activePosition, _setActivePosition] = useState(activePosition)

    useEffect(() => {
        const isGoingBack = activePosition.top === 50 && activePosition.left === 50

        if(!isGoingBack){
            _setActivePosition(activePosition)
        }else{
            setTimeout(() => {
                _setActivePosition(activePosition)
            },1000)
        }
    }, [activePosition])

    return (
        <div 
            className={`masterplan-map ${ active ? 'zoomed' : '' } ${ forceTransitionTransformOrigin ? 'force-transition-transform-origin' : '' }`}
            style={{
                transformOrigin: `${ _activePosition.left }% ${ _activePosition.top }%`
            }}
        >
            <div className="masterplan__areas">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1458 1024" style={{
                    enableBackground: 'new 0 0 1458 1024'
                }}>
                    <g id="Buildings" className="buildings">
                        <g 
                            id="Building-1" 
                            className={`building__group ${ active && activeSectionId === '1' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('1')}
                        >
                            <path id="_x31_" className="building__shape" d="M784.2,787.1l-0.4-97.2H675.1v79.5h8.4v17.6H784.2z M675.1,736.9v-23.8h108.8v23.8H675.1z"/>
                            <text 
                                transform="matrix(1 0 0 1 728.983 767.9135)" 
                                className="building__text"
                            >1</text>
                            <text transform="matrix(1 0 0 1 729.3828 708.9056)" className="building__text">1</text>
                        </g>
                        <g 
                            id="Building-2" 
                            className={`building__group ${ active && activeSectionId === '2' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('2')}
                        >
                            <path id="_x32_" className="building__shape" d="M618.6,687.9l-124.8,0l-0.5,99.2l116.9,0v-17.6h8.4L618.6,687.9z M618.4,736.9H493.8v-23.8h124.5
        V736.9z"/>
                            <text transform="matrix(1 0 0 1 551.1642 764.7865)" className="building__text">2</text>
                            <text transform="matrix(1 0 0 1 551.1635 707.7924)" className="building__text">2</text>
                        </g>
                        <g 
                            id="Building-3" 
                            className={`building__group ${ active && activeSectionId === '3' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('3')}
                        >
                            <polygon id="_x33_" className="building__shape" points="348.1,710.8 333.2,708.2 331.9,715.4 320.7,713.4 321.9,706.3 305.9,703.5 304.7,710.5
        292.3,708.4 289.2,726.5 311.3,730.3 311.3,735.2 306.4,735.2 306.3,741.7 296.8,741.6 296.7,767.1 444.1,768.1 444.1,718.5
        434.7,718.4 434.8,711.3 400.5,711.1 400.4,718.2 381.7,718.1 381.8,711 371.9,710.9 371.8,718 362.5,718 362.5,710.9"></polygon>
                            <text transform="matrix(1 0 0 1 372.1456 749.2528)" className="building__text">3</text>
                        </g>
                        <g 
                            id="Building-4a" 
                            className={`building__group ${ active && activeSectionId === '4a' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('4a')}
                        >
                            <polygon id="_x34_a" className="building__shape" points="444.1,617.1 421.4,617 421.4,624 412,624 412.1,616.9 381.7,616.7 381.7,682.6 444.1,683"></polygon>
                            <text transform="matrix(1 0 0 1 404.9086 658.6473)" className="building__text">4a</text>
                        </g>
                        <g 
                            id="Building-4b" 
                            className={`building__group ${ active && activeSectionId === '4b' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('4b')}
                        >
                            <polygon id="_x34_b" className="building__shape" points="435.8,534.8 423,534.8 423,541.9 413.6,541.8 413.7,534.7 403.7,534.6 403.7,541.7
        394.3,541.7 394.4,534.6 366.3,534.4 366.3,541.5 356.9,541.4 353.8,541.4 334.6,583.8 342.2,583.9 342.1,591 358,591.1 358.1,584
        367.4,584 367.4,591.1 377.3,591.2 377.4,584.1 386.7,584.1 386.7,591.2 415.6,591.4 421.5,578.4 433.3,578.5 433.4,566 431.8,566
        442.7,542 435.7,541.9"></polygon>
                            <text transform="matrix(1 0 0 1 381.5478 570.7157)" className="building__text">4b</text>
                        </g>
                        <g 
                            id="Building-5" 
                            className={`building__group ${ active && activeSectionId === '5' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('5')}
                        >
                            <polygon id="_x35_" className="building__shape" points="383.5,506.2 431.5,506.5 438.5,491.2 443.6,493.5 446.8,486.4 440.4,483.5 442.7,478.4
        449.2,481.3 461.9,453.1 455.5,450.2 457.8,445 464.3,447.9 469.5,436.3 463.1,433.4 474.7,407.6 481.2,410.5 486.5,398.9 480,396
        482.3,390.8 488.8,393.7 501.6,365.3 495.2,362.4 497.5,357.2 504,360.1 511.6,343.3 505.1,340.4 508,334 469.3,316.4 466.4,322.9
        459.9,320 447.1,348.4 453.5,351.3 451.2,356.5 444.7,353.6 439.5,365.2 445.9,368.1 443.6,373.3 437.1,370.3 432.5,380.7
        438.9,383.6 436,390 429.5,387.1 426.6,393.6 433.1,396.5 427.2,409.4 420.8,406.5 410.8,428.4 417.3,431.4 415,436.5 408.5,433.6
        401.5,449.1 408,452 405.6,457.2 399.2,454.3 392.2,469.8 398.6,472.7 396.3,477.9 389.8,474.9 381.7,493 388.1,495.9"></polygon>
                            <text transform="matrix(1 0 0 1 441.3727 423.8246)" className="building__text">5</text>
                        </g>
                        <g 
                            id="Building-6" 
                            className={`building__group ${ active && activeSectionId === '6' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('6')}
                        >
                            <polygon id="_x36_" className="building__shape" points="473.1,250.1 490.7,179.2 566.4,179.5 551.6,250.6"></polygon>
                            <text transform="matrix(1 0 0 1 514.8687 221.6644)" className="building__text">6</text>
                        </g>
                        <g 
                            id="Building-7" 
                            className={`building__group ${ active && activeSectionId === '7' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('7')}
                        >
                            <polygon className="building__shape" points="664,349.6 605.2,323.4 602,310.4 595.5,307.6 604.4,287.7 602.7,287 606.4,278.7 611,273.9
        636.9,285.5 639.3,280 666.3,280.2 666.3,282.8 678.3,282.9 678.1,349.8"></polygon>
                            <polygon className="building__shape" points="616.4,239.5 624.5,248 680.1,248.4 680.2,232.7 680.5,187.1 623.5,186.8 623.3,204.6 621.2,204.6
        621.2,210.3 617.8,210.3 617.7,220.3 616.5,220.3"></polygon>
                            <text transform="matrix(1 0 0 1 639.5 318.25)" className="building__text">7</text>
                            <text transform="matrix(1 0 0 1 646.75 223)" className="building__text">7</text>
                        </g>
                        <g 
                            id="Building-8" 
                            className={`building__group ${ active && activeSectionId === '8' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('8')}
                        >
                            <polygon id="_x38_" className="building__shape" points="612.9,453.5 612.9,375.9 574.9,359 574.5,358.7 524.1,427.3 524.1,453.5"></polygon>
                            <text transform="matrix(1 0 0 1 569.231 421.6498)" className="building__text">8</text>
                        </g>
                        <g 
                            id="Building-9" 
                            className={`building__group ${ active && activeSectionId === '9' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('9')}
                        >
                            <path className="building__shape" d="M684.3,505.5h81.3v0c-0.1,0.1-0.1,0.1-0.1,0.2c0,4.1,0,8.2,0,12.4c0,2.1,0,4.1,0,6.2
        c0,1,0,2.1,0,3.1c0,0.3,0,0.5,0,0.8c0,0.3,0.5,0.1,0.7,0.1c0.5,0,1,0,1.5,0c8.2,0,16.5,0,24.7,0c1.7,0,3.4-0.1,5,0
        c1.4,0.1,3.2,0.3,5.1,0.8c0.7,0.2,1.4,0.5,2.1,0.9c4.1,2.2,5.9,5.7,8.1,9.3c2,3.5,4.1,7.1,6.1,10.6c0.3,0.4,0.5,0.9,0.8,1.3
        c0.1,0.2,0.3,0.4,0.4,0.7c0.1,0.1,0,0.3,0.2,0.2l8.3,1.1c0,5.7,0,11.4,0,17.2c14.4,0,28.7,0,43.1,0c0.5,0,1,0,1.5,0
        c6.7,0,13.4,0,20.1,0c0.7,0,1.5,0,2.2,0c0.8,0,3.4,0,5.9,0c0.9,0,1.8,0,2.7,0c0.6,0,1.3-0.1,1.9-0.2h26.6c0,0.1-0.1,0.2,0.1,0.2
        c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c0.3,0,0.5,0,0.8,0c2.4,0,4.8,0,7.3,0c3,0,6,0,9,0c3.8,0,7.6,0,11.4,0
        c3.4,0,6.8,0,10.2,0c3.4,0,6.6,0.6,9.8-1.9c1.4-1.1,2.1-2.6,3-4.1c0.9-1.5,1.7-2.9,2.6-4.4c1.2-2.1,1.9-4.2,1.6-6.4h29.1
        c4.7,2.5,10.9,0.1,12.3-5.5c0.3-1.3,0.2-7.6,0.2-9c0-0.9,0-1.7,0-2.6c0-6.9,0.1-13.9,0-20.8c0-1.2,0-2.4,0-3.6c0-0.2,0-0.4-0.1-0.6
        c-0.1-0.8-0.3-1.4-0.6-2c0,0,0-0.1-0.1-0.1c-0.6-0.9-1.4-1.6-2.5-2.2c-2-1.3-3.1-2.6-3.1-5.1c0-2.5,1.1-3.8,3.1-5.1
        c2-1.2,3.1-2.5,3.2-5c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0.1-6.6,0-13.3,0-19.9c0-6.4,0-12.8,0-19.2c0-0.6,0-1.1,0-1.7
        c0-7,0-13.9,0-20.9c0-3.5,0-7,0-10.5c0-1.7,0-3.5,0-5.2c0-0.9,0-1.7,0-2.6c0-0.4,0-0.9,0-1.3c0-0.2,0.2-0.8,0-1c0,0,0-0.1,0-0.1
        c-0.1,0-0.1,0-0.2,0c-7,0-13.9,0-20.9,0c-2.2,0-4.4,0-6.6,0c-1.3,0-2.6,0-3.9,0c-3.3,0-6.7,0-10,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0
        c-4.3-0.3-6-2.9-8.1-5.9c-1-1.4-2-2.9-3-4.3c-0.5-0.7-1-1.4-1.5-2.1c-0.2-0.4-0.5-0.7-0.7-1.1c-0.2-0.3-0.3-0.6-0.6-0.7
        c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.4,0c-7,0-13.9,0-20.9,0H821.7h-15.6H684.3V505.5z"/>
                            <rect className="building__shape" x="857.4" y="339.7" transform="matrix(6.123234e-17 -1 1 6.123234e-17 462.6606 1288.9487)" width="36.9" height="147"></rect>
                            <text transform="matrix(1 0 0 1 902.1401 466.1049)" className="building__text">9</text>
                        </g>
                        <g 
                            id="Building-10" 
                            className={`building__group ${ active && activeSectionId === '10' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('10')}
                        >
                            <polygon className="building__shape" points="1208,311.5 1206.7,282.4 1207,282.4 1207,281 1205.9,281 1205.9,271.9 1207.1,271.9 1207.1,270.5 1206.8,270.5
        1206.9,232.8 1182.3,236.9 1143.1,239.3 1134.2,238.8 1134.6,237.9 1131.7,237.9 1129.7,243.2 1129.7,243.4 1126,243.4 1126,243.2
        1124.1,237.8 1121.2,237.8 1121.3,238.1 1114.5,237.8 1078,231.9 1079.4,270 1079.1,270 1079.1,271.4 1080.2,271.4 1080.2,280.5
        1079.1,280.5 1079.1,281.9 1079.4,281.9 1078,310 1107.4,304.2 1133.5,302.6 1133.5,302.8 1136.3,302.8 1138.3,297.5 1138.4,297.3
        1142.3,297.3 1142.3,297.5 1144.2,302.9 1147.1,302.9 1146.9,302.3 1178.6,305 "></polygon>
                            <polygon className="building__shape" points="1284.1,258.6 1284.3,258.6 1289.6,260.5 1289.6,263.4 1288.4,262.9 1287.8,282.4 1288.7,304.6 1291,326.7
        1295.1,349.9 1265.8,349.8 1265.8,350.1 1264.4,350.1 1264.4,349 1255.6,348.9 1255.6,350.1 1254.1,350.1 1254.1,349.8
        1223.5,349.7 1228.2,301.8 1226.2,302.3 1226.2,301.7 1226.2,299.5 1233.3,297.5 1233.4,294.7 1226.3,292.7 1226.3,289.8
        1228.6,290.5 1229,279.6 1226.8,210.3 1254.7,210.4 1254.7,210.1 1256.1,210.1 1256.1,211.2 1264.9,211.3 1264.9,210.1
        1266.3,210.1 1266.3,210.4 1295.4,210.5 1291.5,231.9 1289.3,250.5 1289.7,250.3 1289.7,253.2 1284.3,255.2 1284.1,255.1"></polygon>
                            <text transform="matrix(1 0 0 1 1135.4778 275.1134)" className="building__text">10</text>
                            <text transform="matrix(1 0 0 1 1251.1445 283.7801)" className="building__text">10</text>
                        </g>
                        {/* <g 
                            id="Building-11" 
                            className={`building__group ${ active && activeSectionId === '11' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('11')}
                        >
                            <rect id="_x31_1" x="761" y="607" className="building__shape" width="22.7" height="46.7"></rect>
                            <text transform="matrix(1 0 0 1 761.809 639.9232)" className="building__text">11</text>
                        </g> */}
                        <g 
                            id="Building-12" 
                            className={`building__group ${ active && activeSectionId === '12' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('12')}
                        >
                            <rect id="_x31_2" x="836.4" y="607.6" className="building__shape" width="78.6" height="83.4"></rect>
                            <text transform="matrix(1 0 0 1 860.1445 658.4467)" className="building__text">12</text>
                        </g>
                        <g 
                            id="Building-13" 
                            className={`building__group ${ active && activeSectionId === '13' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('13')}
                        >
                            <polygon id="_x31_3" className="building__shape" points="879.3,702.3 915,702.3 915,746.7 879.3,746.7 879.3,781.8 844.3,781.8 844.3,757.4
        836.1,757.4 836.1,702.3 844.3,702.3"></polygon>
                            <text transform="matrix(1 0 0 1 859.4359 739.1615)" className="building__text">13</text>
                            <rect x="928.5" y="700.9" className="building__shape" width="22.5" height="58.1"></rect>
                        </g>
                        <g 
                            id="Building-14" 
                            className={`building__group ${ active && activeSectionId === '14' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('14')}
                        >
                            <rect x="928.5" y="700.9" className="building__shape" width="22.5" height="58.1"></rect>
                            <polygon className="building__shape" points="984.8,731.2 984.8,730.1 979.4,730.1 979.4,713.4 984.8,713.4 984.8,712.2 982.2,712.2 982.2,700.9
        951,700.9 951,759 982.2,759 982.2,749.1 984.8,749.1 984.8,748 979.4,748 979.4,731.2"></polygon>
                            <text transform="matrix(1 0 0 1 941.6447 737.7006)" className="building__text">14</text>
                        </g>
                        {/* <g 
                            id="Building-15" 
                            className={`building__group ${ active && activeSectionId === '15' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('15')}
                        >
                            <polygon id="_x31_5" className="building__shape" points="1077.1,757.1 1136.3,757.1 1117.7,702.4 1077.1,702.3"></polygon>
                            <text transform="matrix(1 0 0 1 1090.3673 739.3236)" className="building__text">15</text>
                        </g> */}
                        <g 
                            id="Building-16" 
                            className={`building__group ${ active && activeSectionId === '16' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('16')}
                        >
                            <polygon className="building__shape" points="1203.6,719.9 1203.6,647.1 1289.3,647.1 1289.3,758.2 1271.1,758.2 1271.1,725.7 1265.6,725.7
        1265.6,719.9"></polygon>
                            <text transform="matrix(1 0 0 1 1236.9769 689.9564)" className="building__text">16</text>
                        </g>
                        <g 
                            id="Building-18" 
                            className={`building__group ${ active && activeSectionId === '18' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('18')}
                        >
                            <rect id="_x31_8" x="1071.5" y="507.4" className="building__shape" width="76.4" height="48.4"></rect>
                            <text transform="matrix(1 0 0 1 1100.0356 538.6088)" className="building__text">18</text>
                        </g>
                        <g 
                            id="Building-Piazza" 
                            className={`building__group ${ active && activeSectionId === 'piazza' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('piazza')}
                        >
                            <path className="building__shape building--transparent" d="M993.5,595v105.9h160.6l0.2-56h-35v-46.5h35l-0.8-38.4h-97.8v5.8h-5.7c0,0,0,0.6,0,5.9
        c0,10.7-10.7,23.8-26.8,23.8C1007.2,595.5,993.5,595,993.5,595z"/>
                            <text transform="matrix(1 0 0 1 1041 630)" className="building__text">Piazza</text>
                        </g>
                        <g 
                            id="Building-A" 
                            className={`building__group ${ active && activeSectionId === 'a' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('a')}
                        >
                            <polygon className="building__shape" points="668.2,540.6 778.8,540.6 778.8,573.7 668.2,573.7 668.2,641.6 628.8,641.6 628.8,573.7 525.9,573.7 525.9,540.6 628.8,540.6 628.8,442.7 668.2,442.7" data-overlay="A"></polygon>
                            <text transform="matrix(1 0 0 1 639.1781 561.9852)" className="building__text">A</text>
                        </g>
                        {/* <g 
                            id="Building-A-Panopticon" 
                            className={`building__group ${ active && activeSectionId === 'a-panopticon' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('a-panopticon')}
                        >
                            <path className="building__shape building--transparent" d="M563.4,455.9c0.6,0.1,1.3,0.2,1.9,0.3c0.4,0,0,0.7,0,0.9c0,0.1,0,0.2,0,0.2
        c0,0.1-0.1,0.2,0,0.2c0.2,0,0.3,0.1,0.5,0.1c1.3,0.2,2.5,0.5,3.7,0.9c2.5,0.8,4.8,1.8,7.1,3.1c0.1,0.1,0.3,0.2,0.4,0.2
        c0.1,0.1,0.2,0.4,0.4,0.2c0.2-0.3,0.4-0.5,0.5-0.8c0.3-0.4,0.5,0,0.7,0.1c0.3,0.2,0.5,0.4,0.8,0.5c0.1,0.1,0.4,0.1,0.3,0.3
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.6,0.5-0.2,0.7c0.5,0.4,1,0.8,1.5,1.2c2,1.6,3.8,3.5,5.4,5.5c0.4,0.5,0.8,1,1.1,1.6
        c0.2,0.3,0.4,0.5,0.5,0.8c0.3,0.4,0.5-0.1,0.8-0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.3,0.6,0.5,0.8
        c0.1,0.2,0.5,0.5,0.1,0.7c-0.3,0.2-0.6,0.3-0.8,0.5c-0.3,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.3,0.6,0.4,0.9
        c0.3,0.6,0.5,1.2,0.8,1.8c0.5,1.2,0.9,2.4,1.3,3.7c0.4,1.2,0.6,2.5,0.8,3.8c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0.1,0.5
        c0,0.2,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0c0.2,0,0.8-0.3,0.8,0.1c0,0.3,0.1,0.6,0.1,1c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.2
        c0,0.1,0.1,0.2,0,0.2c-0.3,0-0.6,0.1-1,0.1c-0.4,0-0.3,0.4-0.3,0.7c0,0.3,0,0.6,0,1c0,1.3-0.1,2.6-0.2,3.9
        c-0.1,1.3-0.3,2.5-0.6,3.8c-0.1,0.6-0.3,1.2-0.5,1.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.5,0.6,0,0.8c0.3,0.1,0.6,0.2,0.9,0.3
        c0.3,0.1-0.1,0.7-0.2,0.8c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.4-0.5,0.1-0.8,0c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.2,0.2-0.2,0.3
        c-0.1,0.3-0.3,0.6-0.4,0.9c-0.3,0.6-0.6,1.2-0.9,1.7c-1.2,2.3-2.7,4.4-4.4,6.3c-0.4,0.5-0.9,0.9-1.3,1.4c-0.2,0.2,0.1,0.2,0.2,0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.4,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.5,0.4-0.7,0.7c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2,0.2-0.2-0.2-0.3-0.3c-0.1-0.1-0.4-0.8-0.7-0.6c-0.2,0.2-0.5,0.4-0.7,0.6c-2,1.7-4.1,3.1-6.4,4.2c-1.1,0.6-2.3,1.1-3.5,1.5
        c-0.1,0-0.4,0-0.3,0.2c0,0.2,0.1,0.3,0.1,0.5c0.1,0.3,0.4,0.6-0.1,0.7c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0.1-0.7,0.4-0.8,0.1
        c-0.1-0.3-0.2-0.6-0.3-0.9s-0.6,0-0.8,0c-0.6,0.2-1.2,0.3-1.9,0.4c-1.3,0.3-2.5,0.4-3.8,0.5c-1.3,0.1-2.6,0.1-3.9,0.1
        c-0.6,0-1.3-0.1-1.9-0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.2,0.2-0.2,0.3c0,0.2,0,0.3-0.1,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.3,0,0.2
        c-0.6-0.1-1.3-0.2-1.9-0.3c-0.4,0,0-0.7,0-0.9c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0-0.2c-0.2,0-0.3-0.1-0.5-0.1
        c-1.3-0.2-2.5-0.5-3.7-0.9c-2.5-0.8-4.8-1.8-7.1-3.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.2-0.4-0.4-0.2c-0.2,0.3-0.4,0.5-0.5,0.8
        c-0.3,0.4-0.5,0-0.7-0.2c-0.3-0.2-0.5-0.4-0.8-0.5c-0.1-0.1-0.4-0.1-0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.6-0.5,0.2-0.7
        c-0.5-0.4-1-0.8-1.5-1.2c-2-1.6-3.8-3.5-5.4-5.5c-0.4-0.5-0.8-1-1.1-1.6c-0.2-0.3-0.4-0.5-0.5-0.8c-0.3-0.4-0.5,0.1-0.8,0.2
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.3-0.6-0.5-0.8c-0.1-0.2-0.5-0.5-0.1-0.7c0.3-0.2,0.6-0.3,0.8-0.5
        c0.3-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.3-0.6-0.4-0.9c-0.3-0.6-0.5-1.2-0.8-1.8c-0.5-1.2-0.9-2.4-1.3-3.7
        c-0.4-1.2-0.6-2.5-0.8-3.8c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.2,0-0.3-0.1-0.5c0-0.2-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0
        c-0.2,0-0.8,0.3-0.8-0.1c0-0.3-0.1-0.6-0.1-1c0-0.2,0-0.3,0-0.5c0-0.2-0.3-0.4,0-0.5c0.3,0,0.6-0.1,1-0.1c0.4,0,0.3-0.4,0.3-0.7
        c0-0.3,0-0.6,0-1c0-1.3,0.1-2.6,0.2-3.9c0.1-1.3,0.3-2.5,0.6-3.8c0.1-0.6,0.3-1.2,0.5-1.9c0.1-0.3,0.2-0.6,0.3-0.9
        c0.1-0.3,0.5-0.6,0-0.8c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1,0.1-0.7,0.2-0.8c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.4,0.5-0.1,0.8,0
        c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.2-0.2,0.2-0.3c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.6,0.6-1.2,0.9-1.7c1.2-2.3,2.7-4.4,4.4-6.3
        c0.4-0.5,0.9-0.9,1.3-1.4c0.2-0.2-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.4-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c0.2-0.2,0.5-0.4,0.7-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.2,0.2,0.3,0.3c0.1,0.1,0.4,0.8,0.7,0.6c0.2-0.2,0.5-0.4,0.7-0.6
        c2-1.7,4.1-3.1,6.4-4.2c1.1-0.6,2.3-1.1,3.5-1.5c0.1,0,0.4,0,0.3-0.2c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.4-0.6,0.1-0.7
        c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.1,0.7-0.4,0.8-0.1c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.6,0,0.8,0c0.6-0.2,1.2-0.3,1.9-0.4
        c1.3-0.3,2.5-0.4,3.8-0.5c1.3-0.1,2.6-0.1,3.9-0.1c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.2-0.2,0.2-0.3
        C563.3,456.5,563.4,456.2,563.4,455.9z"/>
                        </g> */}
                        <g 
                            id="Building-B" 
                            className={`building__group ${ active && activeSectionId === 'b' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('b')}
                        >
                            <polygon className="building__shape" points="1198,603.1 1292.3,603.1 1292.3,621.6 1292.3,640.2 1198,640.2 1198,721.5 1161.3,721.5
        1161.3,643.2 1121.2,643.2 1121.2,621.6 1121.2,600 1161.3,600 1161.3,521.8 1198,521.8"></polygon>
                            <text transform="matrix(1 0 0 1 1176.7312 626.5282)" className="building__text">B</text>
                        </g>
                        {/* <g 
                            id="Building-B-Annexe" 
                            className={`building__group ${ active && activeSectionId === 'b-annexe' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('b-annexe')}
                        >
                            <rect x="1150.6" y="725.7" className="building__shape" width="120.4" height="32.5"></rect>
                            <text transform="matrix(1 0 0 1 1177.9851 747.7059)" className="building__text">ANNEXE</text>
                        </g> */}
                        {/* <g 
                            id="Building-B-Panopticon" 
                            className={`building__group ${ active && activeSectionId === 'b-panopticon' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('b-panopticon')}
                        >
                            <path className="building__shape building--transparent" d="M1242.8,523.5c0.6,0.1,1.3,0.2,1.9,0.3c0.4,0,0,0.7,0,0.9c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.2,0,0.2
        c0.2,0,0.3,0.1,0.5,0.1c1.3,0.2,2.5,0.5,3.7,0.9c2.5,0.8,4.8,1.8,7.1,3.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.4,0.4,0.2
        c0.2-0.3,0.4-0.5,0.5-0.8c0.3-0.4,0.5,0,0.7,0.1c0.3,0.2,0.5,0.4,0.8,0.5c0.1,0.1,0.4,0.1,0.3,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
        c-0.2,0.2-0.6,0.5-0.2,0.7c0.5,0.4,1,0.8,1.5,1.2c2,1.6,3.8,3.5,5.4,5.5c0.4,0.5,0.8,1,1.1,1.6c0.2,0.3,0.4,0.5,0.5,0.8
        c0.3,0.4,0.5-0.1,0.8-0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.3,0.6,0.5,0.8c0.1,0.2,0.5,0.5,0.1,0.7
        c-0.3,0.2-0.6,0.3-0.8,0.5c-0.3,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.3,0.6,0.4,0.9c0.3,0.6,0.5,1.2,0.8,1.8
        c0.5,1.2,0.9,2.4,1.3,3.7c0.4,1.2,0.6,2.5,0.8,3.8c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0.1,0.5c0,0.2,0.2,0,0.2,0
        c0.1,0,0.2,0,0.2,0c0.2,0,0.8-0.3,0.8,0.1c0,0.3,0.1,0.6,0.1,1c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0,0.2
        c-0.3,0-0.6,0.1-1,0.1c-0.4,0-0.3,0.4-0.3,0.7c0,0.3,0,0.6,0,1c0,1.3-0.1,2.6-0.2,3.9c-0.1,1.3-0.3,2.5-0.6,3.8
        c-0.1,0.6-0.3,1.2-0.5,1.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.5,0.6,0,0.8c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1-0.1,0.7-0.2,0.8
        c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.4-0.5,0.1-0.8,0c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.2,0.2-0.2,0.3c-0.1,0.3-0.3,0.6-0.4,0.9
        c-0.3,0.6-0.6,1.2-0.9,1.7c-1.2,2.3-2.7,4.4-4.4,6.3c-0.4,0.5-0.9,0.9-1.3,1.4c-0.2,0.2,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0.1,0.4,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.5,0.4-0.7,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.2-0.2-0.3-0.3
        c-0.1-0.1-0.4-0.8-0.7-0.6c-0.2,0.2-0.5,0.4-0.7,0.6c-2,1.7-4.1,3.1-6.4,4.2c-1.1,0.6-2.3,1.1-3.5,1.5c-0.1,0-0.4,0-0.3,0.2
        c0,0.2,0.1,0.3,0.1,0.5c0.1,0.3,0.4,0.6-0.1,0.7c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0.1-0.7,0.4-0.8,0.1c-0.1-0.3-0.2-0.6-0.3-0.9
        c-0.1-0.3-0.6,0-0.8,0c-0.6,0.2-1.2,0.3-1.9,0.4c-1.3,0.3-2.5,0.4-3.8,0.5c-1.3,0.1-2.6,0.1-3.9,0.1c-0.6,0-1.3-0.1-1.9-0.1
        c-0.2,0-0.3,0-0.5,0c-0.2,0-0.2,0.2-0.2,0.3c0,0.2,0,0.3-0.1,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.3,0,0.2c-0.6-0.1-1.3-0.2-1.9-0.3
        c-0.4,0,0-0.7,0-0.9c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0-0.2c-0.2,0-0.3-0.1-0.5-0.1c-1.3-0.2-2.5-0.5-3.7-0.9
        c-2.5-0.8-4.8-1.8-7.1-3.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.2-0.4-0.4-0.2c-0.2,0.3-0.4,0.5-0.5,0.8c-0.3,0.4-0.5,0-0.7-0.2
        c-0.3-0.2-0.5-0.4-0.8-0.5c-0.1-0.1-0.4-0.1-0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.6-0.5,0.2-0.7c-0.5-0.4-1-0.8-1.5-1.2
        c-2-1.6-3.8-3.5-5.4-5.5c-0.4-0.5-0.8-1-1.1-1.6c-0.2-0.3-0.4-0.5-0.5-0.8c-0.3-0.4-0.5,0.1-0.8,0.2c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.3-0.6-0.5-0.8c-0.1-0.2-0.5-0.5-0.1-0.7c0.3-0.2,0.6-0.3,0.8-0.5c0.3-0.2-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.3-0.6-0.4-0.9c-0.3-0.6-0.5-1.2-0.8-1.8c-0.5-1.2-0.9-2.4-1.3-3.7c-0.4-1.2-0.6-2.5-0.8-3.8
        c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.2,0-0.3-0.1-0.5c0-0.2-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.8,0.3-0.8-0.1c0-0.3-0.1-0.6-0.1-1
        c0-0.2,0-0.3,0-0.5c0-0.2-0.3-0.4,0-0.5c0.3,0,0.6-0.1,1-0.1c0.4,0,0.3-0.4,0.3-0.7c0-0.3,0-0.6,0-1c0-1.3,0.1-2.6,0.2-3.9
        c0.1-1.3,0.3-2.5,0.6-3.8c0.1-0.6,0.3-1.2,0.5-1.9c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.5-0.6,0-0.8c-0.3-0.1-0.6-0.2-0.9-0.3
        c-0.3-0.1,0.1-0.7,0.2-0.8c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.4,0.5-0.1,0.8,0c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.2-0.2,0.2-0.3
        c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.6,0.6-1.2,0.9-1.7c1.2-2.3,2.7-4.4,4.4-6.3c0.4-0.5,0.9-0.9,1.3-1.4c0.2-0.2-0.1-0.2-0.2-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.4-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.5-0.4,0.7-0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0.2-0.2,0.2,0.2,0.3,0.3c0.1,0.1,0.4,0.8,0.7,0.6c0.2-0.2,0.5-0.4,0.7-0.6c2-1.7,4.1-3.1,6.4-4.2c1.1-0.6,2.3-1.1,3.5-1.5
        c0.1,0,0.4,0,0.3-0.2c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.4-0.6,0.1-0.7c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.1,0.7-0.4,0.8-0.1
        c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.6,0,0.8,0c0.6-0.2,1.2-0.3,1.9-0.4c1.3-0.3,2.5-0.4,3.8-0.5c1.3-0.1,2.6-0.1,3.9-0.1
        c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.2-0.2,0.2-0.3C1242.7,524.2,1242.7,523.9,1242.8,523.5z"/>
                        </g> */}
                        <g 
                            id="Building-E" 
                            className={`building__group ${ active && activeSectionId === 'e' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('e')}
                        >
                            <polygon className="building__shape" points="954.2,627.3 928.5,627.3 928.5,607.6 986.8,607.6 986.8,627.3 984.8,627.3 984.8,640.8 986.8,640.8
        986.8,657.7 984.8,657.7 984.8,671.3 986.8,671.3 986.8,691 928.5,691 928.5,671.3 954.2,671.3"></polygon>
                            <text transform="matrix(1 0 0 1 964.1781 653.452)" className="building__text">E</text>
                        </g>
                        {/* <g 
                            id="Building-Admin" 
                            className={`building__group ${ active && activeSectionId === 'admin' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('admin')}
                        >
                            <polygon className="building__shape" points="1056.7,769.4 1051.8,769.3 1051.8,770.4 1052.8,770.4 1052.8,772.8 1051.8,772.8 1051.8,775.6
        1051.8,781.3 1051.8,788 1056.7,788 1056.7,788.2 1056.9,788.2 1056.9,789.7 1067.5,789.7 1067.5,788.2 1067.7,788.2 1067.7,788
        1072.6,788 1072.6,781.3 1072.6,775.6 1072.7,772.8 1071.6,772.8 1071.6,770.4 1072.6,770.4 1072.6,769.3 1067.7,769.4"></polygon>
                            <polygon className="building__shape" points="1131.1,790.8 1131.1,791.2 1133.5,793.4 1138.4,793.4 1140.7,791.3 1140.7,789.1 1140.7,768.2
        1075,768.1 1075,767.2 1067.5,767.2 1067.5,767.5 1067.5,769.2 1067.7,769.2 1067.7,769.4 1072.6,769.3 1072.6,770.4
        1071.6,770.4 1071.6,772.8 1072.7,772.8 1072.6,775.6 1072.6,781.3 1072.6,788 1067.7,788 1067.7,788.2 1067.5,788.2
        1067.5,789.7 1068.5,790.7 1069.5,790.7 1069.5,792.9 1072.7,796.4 1076.9,796.4 1080.1,793.1 1080.1,790.8 1086.5,790.8
        1086.5,793.1 1087.3,793.1 1087.3,790.8 1088.3,790.8 1091.1,790.8 1093.7,790.8 1093.7,793.1 1094.4,793.1 1094.4,790.8"></polygon>
                            <polygon className="building__shape" points="1056.9,767.2 1056.9,769.2 1056.7,769.2 1056.7,769.4 1067.7,769.4 1067.7,769.2 1067.5,769.2
        1067.5,767.2"></polygon>
                            <polygon className="building__shape" points="1067.5,789.7 1056.9,789.7 1055.9,790.7 1068.5,790.7"></polygon>
                            <polygon className="building__shape" points="993.3,790.8 993.3,791.2 990.9,793.4 986,793.4 983.7,791.3 983.7,789.1 983.7,768.2 1049.4,768.1
        1049.4,767.2 1056.9,767.2 1056.9,767.5 1056.9,769.2 1056.7,769.2 1056.7,769.4 1051.8,769.3 1051.8,770.4 1052.8,770.4
        1052.8,772.8 1051.8,772.8 1051.8,775.6 1051.8,781.3 1051.8,788 1056.7,788 1056.7,788.2 1056.9,788.2 1056.9,789.7
        1055.9,790.7 1054.9,790.7 1054.9,792.9 1051.7,796.4 1047.5,796.4 1044.3,793.1 1044.3,790.8 1037.9,790.8 1037.9,793.1
        1037.1,793.1 1037.1,790.8 1036.1,790.8 1033.3,790.8 1030.8,790.8 1030.8,793.1 1030,793.1 1030,790.8"></polygon>
                            <text transform="matrix(1 0 0 1 990.9519 784.7596)" className="building__text">ADMINISTRATION</text>
                        </g> */}
                        {/* <g 
                            id="Building-Former-Wardens-Building" 
                            className={`building__group ${ active && activeSectionId === 'former-warden' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('former-warden')}
                        >
                            <rect x="979.4" y="731.2" className="building__shape" width="5.5" height="16.8"></rect>
                            <rect x="979.4" y="713.4" className="building__shape" width="5.5" height="16.8"></rect>
                        </g> */}
                        {/* <g 
                            id="Building-Guard-Towers" 
                            className={`building__group ${ active && activeSectionId === 'guard-towers' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('guard-towers')}
                        >
                            <ellipse id="SVGID_1_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -89.8755 33.2968)" cx="240.9" cy="788.2" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_3_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -82.7679 156.4087)" cx="1301.3" cy="788.7" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_5_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -52.7662 154.6612)" cx="1301.3" cy="530.3" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_7_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -12.4516 152.312)" cx="1301.3" cy="183" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_9_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -18.0106 56.7762)" cx="478.4" cy="183" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_11_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -55.6768 99.201)" cx="823.8" cy="527.6" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_13_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -15.827 94.4054)" cx="802.5" cy="183.1" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                            <ellipse id="SVGID_15_" transform="matrix(0.9932 -0.1161 0.1161 0.9932 -85.9397 100.977)" cx="823.9" cy="788.2" rx="5.5" ry="5.5" className="building__shape"></ellipse>
                        </g> */}
                        {/* <g 
                            id="Building-QM" 
                            className={`building__group ${ active && activeSectionId === 'qm' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('qm')}
                        >
                            <polygon
                                className="building__shape"
                                points="1211.7,450.1 1200.8,450 1200.8,447.3 1168.2,446.9 1078.8,441.6 1078.8,391.3 1206.7,391.3
        1206.7,394.4 1210.9,394.4 1210.9,388.9 1225.6,389 1225.7,394.4 1302.3,394.4 1302.3,402.7 1305.3,402.7 1305.3,411.3
        1301.7,411.3 1301.5,445.3 1299.2,445.3 1299.2,448.1 1298.5,448.1 1298.5,448.1 1289.4,448.1 1289.4,476.7 1255.5,476.4
        1211.7,476.6"></polygon>
                            <text transform="matrix(1 0 0 1 1234.6199 439.765)" className="building__text">QM</text>
                        </g> */}
                        {/* <g 
                            id="Building-Industry-Lane" 
                            className={`building__group ${ active && activeSectionId === 'industry-lane' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('industry-lane')}
                        >
                            <path className="building__shape building--transparent" d="M780.5,220.7l269.2,1.2v61.4h-94.9v-22.8H780.5C780.5,260.6,778.9,220.7,780.5,220.7z"/>
                        </g> */}
                        {/* <g 
                            id="Building-Rock-Breaking-Yard" 
                            className={`building__group ${ active && activeSectionId === 'rock-breaking-yard' ? 'active' : '' }`}
                            onClick={() => handleClickBuilding('rock-breaking-yard')}
                        >
                            <path className="building__shape building--transparent" d="M628.7,439.9h38.9v-54c0,0-19.7-3.3-38.9-13C628.7,379.3,628.7,439.9,628.7,439.9z"/>
                        </g> */}
                    </g>
                </svg>
            </div>
        </div>
    )
}
