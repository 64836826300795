import React from "react"

export default ({ image }) => {
    return (

        <div className="container">
            { image && <img src={ image.mediaItemUrl } /> }
        </div>

    )
}
