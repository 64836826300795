import React from 'react'

export default ({options, handleChange, selectedValues}) => {

    const lines = options.split("\n")

    return (
        <div className={'checkboxes'}>
            {lines.map((term, index) => {
                
                return (
                   <>
                   </>
                )
            })}
        </div>
    )
}