import React from "react"
import Link from "./link"

const FeatureStrip = ({
    title,
    copy,
    image,
    link,
    logo,
    colour,
}) => {

    return (
        <div className={`FeatureStrip FeatureStrip-${colour}`}>
            <div className="container">
                <div className="FeatureStrip-main">
                    { logo && 
                        <div className="FeatureStrip-logo" style={{ backgroundImage: `url(${logo.mediaItemUrl})` }} />
                    }
                    <div className="FeatureStrip-content">
                        { title && <h1 dangerouslySetInnerHTML={{ __html: title }} /> }
                        { copy && <div dangerouslySetInnerHTML={{ __html: copy }} className="copy small"/> }
                        { link && <Link to={link.url} className="button">{link.title}</Link> }
                    </div>
                </div>
            </div>
            { image &&
                <div className="FeatureStrip-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />
            }
        </div>
    )
}

export default FeatureStrip
