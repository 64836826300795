import React from 'react'

export default ({
    inputKey,
    handleChange,
    value
}) => {
    return (
        <div className={'subscribe-checkbox'}>
            <label>
                <input 
                    type={'checkbox'} 
                    checked={!!value} 
                    onChange={_ => handleChange(!value)}
                />
                <em />
                <span dangerouslySetInnerHTML={{ __html: inputKey }} />
            </label>
        </div>
    )
}